import { NotificationRuleDevice } from '@appTypes/notificationRules/fields';
import { t } from '@utils/translate';
import { z } from 'zod';

const editRuleFormSchema = z
  .object({
    is_active: z.enum(['1', '2']),
    emails_list: z
      .string()
      .optional()
      .refine(
        (value) => !value || value.split(',').every((email) => /\S+@\S+\.\S+/.test(email.trim())),
        {
          message: t('validationEmailListInvalid'),
        },
      ),
    site_id: z.preprocess(
      (value) => {
        if (typeof value === 'string' && value.trim() === '') {
          return undefined;
        }
        return Number(value);
      },
      z.number({
        required_error: t('validationSiteRequired'),
        invalid_type_error: t('validationSiteInvalidNumber'),
      }),
    ),
    device: z.enum([NotificationRuleDevice.BATTERY, NotificationRuleDevice.CALCULATOR]),
    condition_comparison_operator: z.string(),
    condition_value: z.preprocess(
      (value) => {
        if (typeof value === 'string' && value.trim() === '') {
          return undefined;
        }
        return Number(value);
      },
      z.number({
        required_error: t('validationConditionValueRequired'),
        invalid_type_error: t('validationConditionValueInvalidType'),
      }),
    ),
    comparison_field: z.string({
      required_error: t('validationComparisonFieldRequired'),
    }),
  })
  .superRefine((data, ctx) => {
    if (data.device === NotificationRuleDevice.BATTERY) {
      if (data.condition_value < 0 || data.condition_value > 100) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('validationConditionValueBatteryRange'),
          path: ['condition_value'],
        });
      }
    } else if (data.device === NotificationRuleDevice.CALCULATOR) {
      if (data.condition_value < 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('validationConditionValueCalculatorRange'),
          path: ['condition_value'],
        });
      }
    }
  });

export default editRuleFormSchema;
