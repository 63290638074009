import { PropsWithChildren } from 'react';
import { Grid, GridProps, Paper, styled } from '@mui/material';
import { space } from '@utils/utils';

type FlexProps = {
  cX?: boolean;
  cY?: boolean;
};

export const Flex = ({ children, cX, cY, ...props }: PropsWithChildren<GridProps & FlexProps>) => (
  <Grid
    display="flex"
    alignItems={cX ? 'center' : undefined}
    justifyContent={cY ? 'center' : undefined}
    {...props}
  >
    {children}
  </Grid>
);

export const FlexColumn = ({ children, ...props }: PropsWithChildren<GridProps & FlexProps>) => (
  <Flex direction="column" container {...props}>
    {children}
  </Flex>
);

export const CenterX = ({ children, ...props }: PropsWithChildren<GridProps>) => (
  <Flex cX {...props}>
    {children}
  </Flex>
);

export const CenterY = ({ children, ...props }: PropsWithChildren<GridProps>) => (
  <Flex cY {...props}>
    {children}
  </Flex>
);

export const CenterXY = ({ children, ...props }: PropsWithChildren<GridProps>) => (
  <Flex cX cY {...props}>
    {children}
  </Flex>
);

type GapProps = PropsWithChildren<
  GridProps & {
    size?: number;
  }
>;

export const GapX = ({ children, size = 1, ...props }: GapProps) => (
  <Flex cX sx={{ width: space(size) }} flexShrink={0} {...props}>
    {children}
  </Flex>
);

export const GapY = ({ children, size = 1, ...props }: GapProps) => (
  <Flex cY style={{ height: space(size) }} {...props}>
    {children}
  </Flex>
);

export const PaperLight = styled(Paper)`
  box-shadow: none;
`;
