import { useEffect, useState } from 'react';
import { ConfigurationURLQuery } from '@components/ConfigurationList/types';
import { PageWrapper } from '@components/PageWrapper/PageWrapper';
import { useURLQuery } from '@hooks';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import ConfigurationList from '@pages/ConfigurationList/ConfigurationList';
import { ConfigurationItem } from '@pages/ConfigurationList/types';
import { t } from '@utils/translate';

const defaultSelectedItem = ConfigurationItem.SITES;

const Configuration = () => {
  const { updateURLParam, getURLParamValue } = useURLQuery<ConfigurationURLQuery>();

  const [selectedItem, setSelectedItem] = useState(
    (getURLParamValue('selectedItem') as ConfigurationItem) || defaultSelectedItem,
  );

  useEffect(() => {
    if (selectedItem !== defaultSelectedItem) {
      return;
    }

    updateURLParam('selectedItem', defaultSelectedItem);
    // TODO: Fix rerender cycle here when using correct deps array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTabChange = (value: ConfigurationItem) => {
    setSelectedItem(value);
    updateURLParam('selectedItem', value);
  };

  return (
    <PageWrapper title={t('headingGlobalConfiguration')} headerIcon={SettingsRoundedIcon}>
      <ConfigurationList initSelectedItem={selectedItem} onTabChange={onTabChange} />
    </PageWrapper>
  );
};

export default Configuration;
