import React, { useState } from 'react';
import { DnDItem } from '@appTypes/common';
import {
  DndContext,
  closestCenter,
  PointerSensor,
  KeyboardSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { List, Accordion, AccordionSummary, Typography } from '@mui/material';

interface ReorderGraphsProps {
  items: DnDItem[];
  onReorder: (items: DnDItem[]) => void;
}

export function ReorderGraphs({ items: dndItems, onReorder }: ReorderGraphsProps) {
  const [items, setItems] = useState<DnDItem[]>(dndItems);
  const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor));

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (!over) return;

    if (active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);

      const newItems = arrayMove(items, oldIndex, newIndex);
      setItems(newItems);
      onReorder(newItems);
    }
  };

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd}>
      <SortableContext items={items.map((item) => item.id)} strategy={verticalListSortingStrategy}>
        <List disablePadding>
          {items.map((item) => (
            <SortableListItem key={item.id} item={item} />
          ))}
        </List>
      </SortableContext>
    </DndContext>
  );
}

function SortableListItem({ item }: { item: DnDItem }) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: item.id,
  });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.8 : 1,
  };

  return (
    <Accordion
      style={{
        marginTop: 10,
        backgroundColor: 'white',
        width: '100%',
        ...style,
      }}
      elevation={0}
      {...attributes}
    >
      <AccordionSummary ref={setNodeRef} {...listeners} style={{ cursor: 'move' }}>
        <DragIndicatorIcon />
        <Typography pl={1}>{item.title}</Typography>
      </AccordionSummary>
    </Accordion>
  );
}
