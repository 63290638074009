import { regex } from '@settings/regex';
import { t } from '@utils/translate';
import { z } from 'zod';

const organizationFormSchema = z.object({
  name: z
    .string({
      required_error: t('validationOrganizationNameRequired'),
      invalid_type_error: t('validationOrganizationNameInvalidType'),
    })
    .regex(regex.organizationName, {
      message: t('validationOrganizationNameInvalidCharacters'),
    })
    .min(2, { message: t('validationOrganizationNameMinLength') })
    .max(50, { message: t('validationOrganizationNameMaxLength') }),
  manager_name: z
    .string({
      required_error: t('validationManagerNameRequired'),
      invalid_type_error: t('validationManagerNameInvalidType'),
    })
    .regex(regex.managerName, { message: t('validationManagerNameInvalidCharacters') })
    .min(4, { message: t('validationManagerNameMinLength') })
    .max(50, { message: t('validationManagerNameMaxLength') }),
  manager_email: z
    .string({
      required_error: t('validationEmailRequired'),
      invalid_type_error: t('validationEmailInvalidType'),
    })
    .email({ message: t('validationEmailInvalidType') }),
  manager_phone: z
    .string({
      required_error: t('validationPhoneRequired'),
      invalid_type_error: t('validationPhoneInvalidType'),
    })
    .regex(regex.phoneNumber, { message: t('validationPhoneInvalidCharacters') })
    .min(4, { message: t('validationPhoneMinLength') })
    .max(20, { message: t('validationPhoneMaxLength') }),
  street: z
    .string({
      required_error: t('validationStreetRequired'),
      invalid_type_error: t('validationStreetInvalidType'),
    })
    .regex(regex.street, { message: t('validationStreetInvalidCharacters') })
    .min(2, { message: t('validationStreetMinLength') })
    .max(50, { message: t('validationStreetMaxLength') }),
  city: z
    .string({
      required_error: t('validationCityRequired'),
      invalid_type_error: t('validationCityInvalidType'),
    })
    .regex(regex.city, {
      message: t('validationCityInvalidCharacters'),
    })
    .min(2, { message: t('validationCityMinLength') })
    .max(50, { message: t('validationCityMaxLength') }),
  postal_code: z
    .string({
      required_error: t('validationPostalCodeRequired'),
      invalid_type_error: t('validationPostalCodeInvalidType'),
    })
    .regex(regex.postalCode, { message: t('validationPostalCodeInvalidCharacters') })
    .min(4, { message: t('validationPostalCodeMinLength') })
    .max(10, { message: t('validationPostalCodeMaxLength') }),
  country: z
    .string({
      required_error: t('validationCountryRequired'),
      invalid_type_error: t('validationCountryInvalidType'),
    })
    .regex(regex.country, {
      message: t('validationCountryInvalidCharacters'),
    })
    .min(4, { message: t('validationCountryMinLength') })
    .max(30, { message: t('validationCountryMaxLength') }),
});

export default organizationFormSchema;
