import { t } from '@utils/translate';
import { format } from 'date-fns';
import { palette } from 'styles/palette';
import { formatNumberWithUnit } from './helpers';

const itemStyle: React.CSSProperties = {
  display: 'flex',
  gap: '5px',
  fontWeight: 'normal',
  alignItems: 'center',
};

/* 
  Note: this component is used by ReactDOMServer.renderToString method.
  It is parsed into plain html string and used in 
  ECharts custom tooltip callback instead.
  It does not support styled components nor Mui, can be styled by style prop only.
*/

const Marker = ({ color }: { color?: string }) => {
  const markerStyle: React.CSSProperties = {
    display: 'inline-block',
    position: 'relative',
    width: '14px',
    height: '2px',
    flexShrink: 0,
    backgroundColor: color || '#000',
  };

  const afterStyle: React.CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '4px',
    height: '4px',
    border: `2px solid ${color || '#000'}`,
    borderRadius: '50%',
    backgroundColor: 'white',
  };

  return (
    <span style={markerStyle}>
      <span style={afterStyle} />
    </span>
  );
};

const EchartsTooltip = ({ params }: { params: any }) => {
  const date = new Date(params[0]?.axisValue);
  const formattedDate = format(date, 'dd MMM yyyy HH:mm');

  return (
    <div>
      <div style={{ fontWeight: 'bold', marginBottom: 5, color: palette.primary.main }}>
        {formattedDate}
      </div>
      {params.map((item: any) => {
        const { seriesId, color } = item;
        const [name, unit] = seriesId.split('|');
        const value = item?.value?.[1] ?? null;
        const formattedValue = (
          <span style={{ fontWeight: 'bold' }}>
            {formatNumberWithUnit(parseInt(value, 10), unit)}
          </span>
        );
        const noData = <span style={{ color: palette.error.dark }}>{t('labelNoDataOutage')}</span>;

        return (
          <div key={seriesId} style={itemStyle}>
            <Marker color={color} />
            <span>{name}: </span>
            {value !== null ? formattedValue : noData}
          </div>
        );
      })}
    </div>
  );
};

export default EchartsTooltip;
