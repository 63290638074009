import { NotificationRuleDevice } from '@appTypes/notificationRules/fields';
import { CustomSelectBoxProps } from '@components/SelectBox/SelectBox';
import { Widgets } from '@mui/icons-material';
import { t } from '@utils/translate';
import { LabeledSelectBox } from '../Labeled/LabeledSelectBox';

const deviceSelectBoxOpts = [
  {
    id: NotificationRuleDevice.BATTERY,
    name: t('labelBattery'),
  },
  {
    id: NotificationRuleDevice.CALCULATOR,
    name: t('labelDeviceFlows'),
  },
];

export const DeviceSelectBox = (
  props: Omit<CustomSelectBoxProps, 'options' | 'disableEmptyValue'>,
) => (
  <LabeledSelectBox
    {...props}
    IconComponent={Widgets}
    label={t('labelDevice')}
    options={deviceSelectBoxOpts}
    disableEmptyValue
    variant="outlined"
  />
);
