import { ChangeEvent, ForwardedRef, forwardRef } from 'react';
import { EllipsisTypography } from '@components/EllipsisTypography';
import { StandardTextFieldProps, TextField, Typography } from '@mui/material';
import { useFormGroupContext } from '@providers/FormGroupProvider';
import { formatDate } from '@utils/data/formatDate';
import { t } from '@utils/translate';
import { space } from '@utils/utils';
import { palette } from 'styles/palette';
import { PasswordField } from './PasswordField/PasswordField';

export type FormInputCustomComponentProps = {
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  readOnly?: boolean;
  value?: unknown;
  error?: boolean;
  label?: string;
  customComponentProps?: object;
};

type FormInputCustomComponent = (props: FormInputCustomComponentProps) => JSX.Element;

export type FormInputProps = {
  label: string;
  name: string;
  useInputLabel?: boolean;
  CustomComponent?: FormInputCustomComponent;
  customComponentProps?: object;
  readOnly?: boolean | undefined;
  isDate?: boolean | undefined;
  initValue?: string | Nullable<number> | undefined;
  readOnlyStyle?: React.CSSProperties;
  error?: { message?: string };
} & Omit<StandardTextFieldProps, 'error'>;

export const FormInput = forwardRef(
  (
    {
      label,
      readOnly,
      isDate,
      initValue,
      name,
      required,
      useInputLabel,
      error,
      CustomComponent,
      customComponentProps,
      onChange,
      readOnlyStyle,
      ...textFieldProps
    }: FormInputProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const formGroupContext = useFormGroupContext();
    const isWithinFormGroupContext = !!formGroupContext?.subscribeToFormGroup;

    if (isWithinFormGroupContext) {
      formGroupContext.subscribeToFormGroup(name);
    }

    const inpTestId =
      (
        textFieldProps as unknown as StandardTextFieldProps & {
          ['data-testid']: string | undefined;
        }
      )['data-testid'] || `${name}-form-input`;

    const readOnlyVal =
      isDate && initValue && typeof initValue !== 'number' ? formatDate(initValue) : initValue;

    const displayReadOnly = readOnly && !CustomComponent;

    return (
      <>
        {!useInputLabel && (
          <EllipsisTypography
            maxWidth="100%"
            tooltipProps={{ placement: 'top-start' }}
            color={!error ? palette.primary.light : palette.error.main}
          >
            {`${label}${required && !readOnly ? ' *' : ''}`}
          </EllipsisTypography>
        )}
        {displayReadOnly ? (
          <div style={{ position: 'relative', top: '-10px' }}>
            {useInputLabel && <Typography variant="caption">{label}</Typography>}
            <EllipsisTypography
              maxWidth="100%"
              tooltipProps={{ placement: 'top-start' }}
              mt={1.5}
              style={readOnlyStyle}
              fontSize={space(2.25)}
              data-testid={inpTestId}
            >
              {readOnlyVal || t('labelNoData')}
            </EllipsisTypography>
          </div>
        ) : (
          <>
            {CustomComponent && (
              <CustomComponent
                {...customComponentProps}
                {...textFieldProps}
                label={useInputLabel ? label : undefined}
                readOnly={readOnly}
                onChange={onChange}
                name={name}
                error={!!error}
              />
            )}
            {!CustomComponent && textFieldProps.type !== 'password' && (
              <TextField
                {...textFieldProps}
                label={useInputLabel ? label : undefined}
                onChange={onChange}
                name={name}
                error={!!error}
                inputProps={{
                  'data-testid': inpTestId,
                  ...(textFieldProps.inputProps || {}),
                }}
                data-testid={undefined}
                ref={ref}
              />
            )}
            {!CustomComponent && textFieldProps.type === 'password' && (
              <PasswordField
                {...textFieldProps}
                label={useInputLabel ? label : undefined}
                onChange={onChange}
                name={name}
                error={!!error}
                inputProps={{
                  'data-testid': inpTestId,
                  ...(textFieldProps.inputProps || {}),
                }}
                data-testid={undefined}
                ref={ref}
              />
            )}
            {error?.message && (
              <Typography data-testid={`${inpTestId}-error`} variant="subtitle2" color="error">
                {error.message.toString()}
              </Typography>
            )}
          </>
        )}
      </>
    );
  },
);
