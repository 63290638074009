export type AppEnvironment = 'production' | 'development';

export enum FEATURE_FLAGS {
  DISABLE_COMING_SOON_OVERLAYS = 'comingSoonOverlays',
  ADVANCED_DATA_EXPORT = 'advancedDataExport',
  GRAPH_EDITOR = 'graphEditor',
  NEW_TELEMETRY_SUBSYSTEM = 'useNewTelemetry',
  TEST_FLAG1 = 'testFlag1',
  TEST_FLAG2 = 'testFlag2',
}

export type FeatureFlagConfig = {
  name: FEATURE_FLAGS;
  label: string;
  environments: AppEnvironment[];
  backendOnly?: boolean;
};

export type PathParam = Nullable<string | number>;

export interface DnDItem {
  id: string;
  title: string;
}
