import { useMoveUserToOrganizationMutation } from '@apiRtk/users';
import UserSelectOrganization from '@components/Form/UserSelectOrganization';
import FormModal from '@components/Modals/FormModal';
import { notificationService } from '@services/notificationService/notificationService';
import { t } from '@utils/translate';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

type FormValues = {
  userId: Nullable<number>;
};

const defaultValues = {
  userId: null,
};

export const UserRelationModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [moveUserToOrganization, { isLoading }] = useMoveUserToOrganizationMutation();
  const params = useParams();
  const organizationId = parseInt(params.id!, 10);

  const { handleSubmit, control, setValue, formState, reset } = useForm<FormValues>({
    defaultValues,
  });

  const handleFormSubmit: SubmitHandler<FormValues> = async (fieldValues) => {
    const { userId } = fieldValues;

    if (!userId) {
      return;
    }

    try {
      await moveUserToOrganization({
        userId,
        organization_id: organizationId,
      }).unwrap();

      setOpen(false);
      reset();
      notificationService.success(t('successUserAdded'));
    } catch (err) {
      notificationService.error(t('errorAddUserToOrganization'));
    }
  };

  return (
    <FormModal
      open={open}
      setOpen={setOpen}
      dialogTitle={t('dialogTitleAddRelatedUser')}
      onSubmit={handleSubmit(handleFormSubmit)}
      submitDisabled={!formState.isValid || isLoading}
    >
      <UserSelectOrganization
        organizationId={organizationId}
        name="userId"
        control={control}
        setValue={setValue}
        required
      />
    </FormModal>
  );
};
