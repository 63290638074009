import { PropsWithChildren } from 'react';
import { CenterXY } from '@components/LayoutUtils';
import RetryIcon from '@mui/icons-material/Cached';
import { Alert, IconButton, AlertColor } from '@mui/material';
import { t } from '@utils/translate';

interface AlertWithRefetchProps {
  severity?: AlertColor;
  onRetryClick: () => void;
}

const AlertWithRefetch = ({
  severity = 'error',
  onRetryClick,
  children,
}: PropsWithChildren<AlertWithRefetchProps>) => (
  <CenterXY>
    <Alert
      severity={severity}
      action={
        <IconButton size="small" onClick={onRetryClick} title={t('buttonRetry')}>
          <RetryIcon />
        </IconButton>
      }
    >
      {children}
    </Alert>
  </CenterXY>
);

export default AlertWithRefetch;
