import { ConditionComparisonOperator } from '@appTypes/notificationRules/fields';
import { CustomSelectBoxProps } from '@components/SelectBox/SelectBox';
import { Timeline } from '@mui/icons-material';
import { t } from '@utils/translate';
import { LabeledSelectBox } from '../Labeled/LabeledSelectBox';

const conditionComparisonSelectBoxOpts = [
  {
    id: ConditionComparisonOperator.GREATER_OR_EQUAL,
    name: ConditionComparisonOperator.GREATER_OR_EQUAL,
  },
  {
    id: ConditionComparisonOperator.LESS_OR_EQUAL,
    name: ConditionComparisonOperator.LESS_OR_EQUAL,
  },
];

export const ConditionComparisonSelectBox = (
  props: Omit<CustomSelectBoxProps, 'options' | 'disableEmptyValue'>,
) => (
  <LabeledSelectBox
    {...props}
    IconComponent={Timeline}
    label={t('labelIs')}
    options={conditionComparisonSelectBoxOpts}
    disableEmptyValue
    variant="outlined"
  />
);
