import { DashboardDto } from '@appTypes/models/dashboard.dto';
import { coreApi } from './core';

const dashboardsApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getDashboard: builder.query<DashboardDto, string>({
      query: (dashboardId) => `dashboards/${dashboardId}`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetDashboardQuery } = dashboardsApi;
