import { UserDto } from '@appTypes/models/user.dto';
import ConfirmModal from '@components/Modals/ConfirmModal';
import { Typography } from '@mui/material';
import { t } from '@utils/translate';

interface ToggleUserActivationModalProps {
  user: UserDto | undefined;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: () => void;
}

export const ToggleUserActivationModal = ({
  user,
  open,
  setOpen,
  onConfirm,
}: ToggleUserActivationModalProps) => {
  const handleFormSubmit = () => {
    onConfirm();
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  if (!user) {
    return null;
  }

  const dialogTitle = user.is_active ? t('buttonDeactivateUser') : t('buttonActivateUser');
  const dialogText = user.is_active
    ? t('dialogTextUserDeactivation', {
        lastName: user.last_name,
        firstName: user.first_name,
      })
    : t('dialogTextUserActivation', {
        lastName: user.last_name,
        firstName: user.first_name,
      });

  return (
    <ConfirmModal
      open={open}
      setOpen={setOpen}
      dialogTitle={dialogTitle}
      confirmButtonCaption={user.is_active ? t('buttonDeactivateUser') : t('buttonActivateUser')}
      onConfirm={handleFormSubmit}
      onCancel={handleCancel}
    >
      <Typography variant="body1">{dialogText}</Typography>
    </ConfirmModal>
  );
};
