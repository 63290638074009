import {
  BatteryComparisonField,
  CalculatorComparisonField,
  NotificationRuleDevice,
} from '@appTypes/notificationRules/fields';
import { CustomSelectBoxProps } from '@components/SelectBox/SelectBox';
import { Timeline } from '@mui/icons-material';
import { t } from '@utils/translate';
import { LabeledSelectBox } from '../Labeled/LabeledSelectBox';

const calculatorComparisonFieldOpts = [
  {
    id: CalculatorComparisonField.CONSUMPTION,
    name: t('labelComparisonConsumption'),
  },
  {
    id: CalculatorComparisonField.GRID_CONSUMPTION,
    name: t('labelComparisonGridConsumption'),
  },
  {
    id: CalculatorComparisonField.GRID_BACKFLOW,
    name: t('labelComparisonGridBackflow'),
  },
  {
    id: CalculatorComparisonField.PV_GENERATION,
    name: t('labelComparisonPvGeneration'),
  },
  {
    id: CalculatorComparisonField.BATTERY_CHARGE,
    name: t('labelComparisonBatteryCharge'),
  },
  {
    id: CalculatorComparisonField.BATTERY_DISCHARGE,
    name: t('labelComparisonBatteryDischarge'),
  },
];

const batteryComparisonFieldOpts = [
  { id: BatteryComparisonField.SOC, name: t('labelComparisonSoc') },
  { id: BatteryComparisonField.SOH, name: t('labelComparisonSoh') },
];

export const ComparisonFieldSelectBox = ({
  currentDevice,
  ...props
}: Omit<CustomSelectBoxProps, 'options' | 'disableEmptyValue'> & {
  currentDevice: `${NotificationRuleDevice}`;
}) => {
  const options =
    currentDevice === NotificationRuleDevice.CALCULATOR
      ? calculatorComparisonFieldOpts
      : batteryComparisonFieldOpts;

  return (
    <LabeledSelectBox
      {...props}
      IconComponent={Timeline}
      label={t('labelValue')}
      options={options}
      disableEmptyValue
      variant="outlined"
    />
  );
};
