import { UserRole } from '@appTypes/models/user.dto';
import { regex } from '@settings/regex';
import { t } from '@utils/translate';
import { z } from 'zod';

export const userFormSchema = z.object({
  organization_id: z.number({
    required_error: t('validationOrganizationIdRequired'),
    invalid_type_error: t('validationOrganizationIdInvalidType'),
  }),
  email: z
    .string({
      required_error: t('validationEmailRequired'),
      invalid_type_error: t('validationEmailInvalidType'),
    })
    .email({ message: t('validationEmailInvalidType') }),
  first_name: z
    .string({
      required_error: t('validationFirstNameRequired'),
      invalid_type_error: t('validationFirstNameInvalidType'),
    })
    .regex(regex.userFirstName, { message: t('validationFirstNameInvalidCharacters') })
    .min(2, { message: t('validationFirstNameMinLength') })
    .max(50, { message: t('validationFirstNameMaxLength') }),
  last_name: z
    .string({
      required_error: t('validationLastNameRequired'),
      invalid_type_error: t('validationLastNameInvalidType'),
    })
    .regex(regex.userLastName, { message: t('validationLastNameInvalidCharacters') })
    .min(2, { message: t('validationLastNameMinLength') })
    .max(50, { message: t('validationLastNameMaxLength') }),
  phone: z
    .string({
      required_error: t('validationPhoneRequired'),
      invalid_type_error: t('validationPhoneInvalidType'),
    })
    .regex(regex.phoneNumber, { message: t('validationPhoneInvalidCharacters') })
    .min(4, { message: t('validationPhoneMinLength') })
    .max(20, { message: t('validationPhoneMaxLength') }),
  role: z.nativeEnum(UserRole, {
    required_error: t('validationRoleNameRequired'),
    invalid_type_error: t('validationRoleNameInvalidType'),
  }),
});
