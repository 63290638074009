import { UserRole, UserSimpleDto } from '@appTypes/models/user.dto';
import { ButtonAdd } from '@components/Buttons/Buttons';
import { CenterX } from '@components/LayoutUtils';
import { SearchInput } from '@components/SearchInput';
import TanStackTable from '@components/TanStackTable/TanStackTable';
import { useRedirect, useFilters, useCurrentUserInfo } from '@hooks';
import { useDataManipulation } from '@hooks/useDataManipulation';
import { Button, styled } from '@mui/material';
import CONFIG from '@settings/config';
import { ColumnDef } from '@tanstack/react-table';
import { createFullName } from '@utils/data/createFullName';
import { t } from '@utils/translate';
import { normaliseString } from '@utils/utils';
import { palette } from 'styles/palette';

const getRole = (roleName: string, isActive: boolean) => {
  if (!isActive) {
    return t('statusUserDeactivated');
  }

  if (roleName === UserRole.ADMIN) {
    return t('roleManager');
  }

  if (roleName === UserRole.USER) {
    return t('roleTechnician');
  }

  return roleName;
};

type UsersGridCellProps = {
  roleName: string;
  id: number;
  isActive: boolean;
};

const CellWithRedirectButton = ({ roleName, id, isActive }: UsersGridCellProps) => {
  const redirect = useRedirect();

  return (
    <CenterX
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        minWidth: '220px',
      }}
    >
      <span style={{ color: isActive ? undefined : palette.error.main }}>
        {getRole(roleName, isActive)}
      </span>
      <Button variant="outlined" onClick={() => redirect((paths) => paths.configurationUser(id))}>
        {t('buttonUserDetail')}
      </Button>
    </CenterX>
  );
};

const StyledUserCell = styled('div')<{ isActive: boolean }>(({ isActive }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '0 0 auto',
  minWidth: '150px',
  maxWidth: '200px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: isActive ? undefined : palette.neutral.main,
  '& .userName': {
    fontWeight: 'bold',
  },
}));

const columns: ColumnDef<UserSimpleDto>[] = [
  {
    header: 'User',
    accessorKey: 'last_name',
    enableSorting: false,
    cell: ({ row }) => (
      <StyledUserCell isActive={row.original.is_active}>
        <span className="userName">
          {createFullName(row.original.first_name, row.original.last_name)}
        </span>
        <span>{row.original.email}</span>
      </StyledUserCell>
    ),
  },
  {
    header: 'Role',
    accessorKey: 'role',
    enableSorting: false,
    cell: ({ row }) => (
      <div style={{ flex: '0 0 auto' }}>
        <CellWithRedirectButton
          roleName={row.original.role.name}
          id={row.original.id}
          isActive={row.original.is_active}
        />
      </div>
    ),
  },
];

interface UserListProps {
  userData: UserSimpleDto[];
  onAddRelatedUser: () => void;
}

const UserList = ({ userData, onAddRelatedUser }: UserListProps) => {
  const { isUserOnly } = useCurrentUserInfo();

  const { sorting, searchString, pagination, setSorting, setSearchString, setPagination } =
    useFilters({
      enableUrlMirroring: false,
      enableSorting: false,
      pageSize: CONFIG.ENDPOINTS.ITEMS_PER_PAGE_SMALL,
      sortingState: [],
    });

  const { data, totalItems } = useDataManipulation<UserSimpleDto>({
    data: userData,
    searchString,
    sorting,
    pagination,
    getSearchableString: (user) =>
      normaliseString([user.first_name, user.last_name, user.email].join(' ')),
    getSortableValue: (user, sortId) => user[sortId as keyof UserSimpleDto],
    enableSorting: false,
  });

  return (
    <>
      <ButtonAdd sx={{ my: 2 }} onClick={onAddRelatedUser} disabled={isUserOnly}>
        {t('buttonAddRelatedUser')}
      </ButtonAdd>
      <SearchInput value={searchString} onValueChange={setSearchString} sx={{ mb: 2 }} />
      <TanStackTable<UserSimpleDto>
        columns={columns}
        rows={data}
        itemsCount={totalItems}
        sorting={sorting}
        pagination={pagination}
        onSortingChange={(value) => setSorting(value)}
        onPaginationChange={(value) => setPagination(value)}
      />
    </>
  );
};

export default UserList;
