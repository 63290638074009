import { EcdDevicesPermissions } from '@appTypes/models/site.dto';
import { t } from '@utils/translate';

export const EcdPermissionTitles = {
  [EcdDevicesPermissions.VIEW]: t('ecdPermissionView'),
  [EcdDevicesPermissions.BASIC_EDIT]: t('ecdPermissionBasicEdit'),
  [EcdDevicesPermissions.ADVANCED_EDIT]: t('ecdPermissionAdvancedEdit'),
} as const;

export const getEcdPermissionTitle = (ecdPermission?: Nullable<EcdDevicesPermissions>): string =>
  EcdPermissionTitles[ecdPermission ?? EcdDevicesPermissions.VIEW];
