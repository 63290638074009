import { SitesSelect } from '@components/SitesSelect/SitesSelect';
import { t } from '@utils/translate';
import { EcdDetailField } from '../EcdDetails/EcdDetails';
import {
  prepareEcdDetailsFormInitVal,
  PrepareEcdDetailsFormInitValData,
} from './prepareEcdDetailsFormInitVal';

const fields: Omit<EcdDetailField, 'initValue'>[] = [
  { name: 'name', label: t('labelEcdName') },
  { name: 'id', label: t('labelEcdId'), readOnly: true },
  {
    name: 'creation_date',
    label: t('labelCreationDate'),
    readOnly: true,
    isDate: true,
  },
  {
    name: 'last_modify_date',
    label: t('labelLastModifyDate'),
    readOnly: true,
    isDate: true,
  },
  { name: 'status', label: t('labelStatus'), readOnly: true },
  {
    name: 'site_id',
    label: t('labelSite'),
    CustomComponent: ({ ...props }) => (
      // @ts-ignore
      <SitesSelect {...props} emptyValueContent="-" sx={{ '& .MuiSelect-select': { py: 1.6 } }} />
    ),
  },
];

export const prepareEcdDetailsForm = (data: PrepareEcdDetailsFormInitValData) => {
  const ecdDetailsInitVal = prepareEcdDetailsFormInitVal(data);

  const ecdDetailsFields = fields.map((field) => ({
    ...field,
    initValue: ecdDetailsInitVal[field.name as keyof typeof ecdDetailsInitVal],
  }));

  return { ecdDetailsFields, ecdDetailsInitVal };
};
