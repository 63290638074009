export enum ConfiguredItem {
  ECDS = 'ECDS',
  SITES = 'SITES',
  ORGANIZATIONS = 'ORGANIZATIONS',
  USERS = 'USERS',
}

export type ConfigurationURLQuery = {
  selectedItem: string;
};
