import { Tooltip } from '@components/Tooltip/Tooltip';
import { ReactComponent as IconInfo } from '@icons/info.svg';
import { t } from '@utils/translate';

const localToDictionaryKeys = {
  systemStatus: 'tooltipSystemStatus',
  returnOnInvestment: 'tooltipReturnOnInvestment',
  avoidedEmmisions: 'tooltipAvoidedEmmisions',
  batteryHealth: 'tooltipBatteryHealth',
  consumptionMix: 'tooltipConsumptionMix',
} as const;

type LocalStringKeys = keyof typeof localToDictionaryKeys;

interface CardTooltipProps {
  textKey: LocalStringKeys;
}

export const CardTooltip = ({ textKey }: CardTooltipProps) => {
  const dictionaryKey = localToDictionaryKeys[textKey];
  return (
    <Tooltip trigger={<IconInfo />} placement="top">
      {t(dictionaryKey)}
    </Tooltip>
  );
};
