import { ButtonBack } from '@components/Buttons/Buttons';
import { ConfiguredItem } from '@components/ConfigurationList/types';
import { TitleBar } from '@components/TitleBar/TitleBar';
import { FlashOn } from '@mui/icons-material';
import { useEcdConfigurationContext } from '@providers/EcdConfigurationProvider';
import { t } from '@utils/translate';
import { paths } from 'paths';

export const EcdConfigurationHeader = ({ title }: { title: string | undefined }) => {
  const { isFormFreezed } = useEcdConfigurationContext();

  return (
    <>
      <ButtonBack
        sx={{ ml: 1 }}
        disabled={isFormFreezed}
        path={paths.configuration}
        query={{ selectedItem: ConfiguredItem.ECDS }}
      >
        {t('buttonBackToConfigurationList')}
      </ButtonBack>
      <TitleBar
        my={4}
        title={t('headingEcdConfiguration', { subject: title || t('labelEcd') })}
        IconComponent={FlashOn}
      />
    </>
  );
};
