import { PerformanceOverTimeDto } from '@appTypes/models/cards.dto';
import { PerformanceOverTimeParamsDto } from '@appTypes/models/common.dto';
import { convertLocalDateToApiString, createParams } from '@utils/utils';
import { coreApi, extraParams } from './core';

const performanceOverTimeApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getPerformanceOverTime: builder.query<PerformanceOverTimeDto, PerformanceOverTimeParamsDto>({
      query: ({ startDate, endDate, siteId, solarBaseline, ...params }) =>
        `sites/${siteId}/performance?${createParams({
          start: convertLocalDateToApiString(startDate),
          end: convertLocalDateToApiString(endDate),
          solar_baseline: solarBaseline,
          ...params,
          ...extraParams,
        })}`,
      providesTags: (result, error, { siteId, solarBaseline }) => [
        { type: 'PerformanceOverTime', id: siteId, solarBaseline },
      ],
    }),
  }),
  overrideExisting: false,
});

export const { useGetPerformanceOverTimeQuery } = performanceOverTimeApi;
