import { ButtonBack } from '@components/Buttons/Buttons';
import { TitleBar } from '@components/TitleBar/TitleBar';
import { t } from '@utils/translate';
import { paths } from 'paths';
import CreateSiteForm from './CreateSiteForm';

const CreateSite = () => (
  <>
    <ButtonBack path={paths.configuration}>{t('buttonBackToConfigurationList')}</ButtonBack>
    <TitleBar my={4} title={t('headingCreateSite')} titleVariant="h5" />
    <CreateSiteForm />
  </>
);

export default CreateSite;
