import { useEffect, useRef, useState } from 'react';
import { useGetAnalyticsQuery, useUpdateOrderMutation } from '@apiRtk/analytics';
import { HttpStatusCode } from '@appTypes/api/error';
import { DnDItem, FEATURE_FLAGS } from '@appTypes/common';
import AlertWithRefetch from '@components/AlertWithRefetch/AlertWithRefetch';
import { ButtonPrimary, ButtonPrimaryOutlined } from '@components/Buttons/Buttons';
import DateRangePanel from '@components/Charts/DateRangePanel/DateRangePanel';
import { DateTimeRangeProvider } from '@components/Charts/DateRangePanel/DateTimeRangeContext';
import EchartWrapper from '@components/Charts/EchartWrapper';
import { Flex } from '@components/LayoutUtils';
import { SiteHeaderWrapper } from '@components/SiteHeaderWrapper/SiteHeaderWrapper';
import { Spinner } from '@components/Spinner';
import CheckIcon from '@mui/icons-material/Check';
import ReorderIcon from '@mui/icons-material/Expand';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { selectAuthSlice } from '@redux/slices/auth';
import { isFeatureFlagEnabled } from '@services/featureFlags';
import { notificationService } from '@services/notificationService/notificationService';
import { prepareError } from '@utils/data/prepareError';
import { t } from '@utils/translate';
import ReactECharts from 'echarts-for-react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { GraphEditor } from './GraphEditor/GraphEditor';
import { ReorderGraphs } from './ReorderGraphs';

const DataAnalysis = () => {
  const { currentUser } = useSelector(selectAuthSlice);
  const params = useParams();
  const siteId = parseInt(params.id!, 10);
  const refs = useRef<Array<Nullable<ReactECharts>>>([]);
  const { refetch, data: graphs, isLoading, error, isError } = useGetAnalyticsQuery(siteId);
  const [updateOrder, { isLoading: updatingOrder }] = useUpdateOrderMutation();

  const [dndItems, setDndItems] = useState<DnDItem[]>([]);
  const [initialDndItems, setInitialDndItems] = useState<DnDItem[]>([]);

  const [isReordering, setIsReordering] = useState(false);

  useEffect(() => {
    if (graphs?.length) {
      const newDndItems = graphs.map(({ graphId, title }) => ({
        id: graphId,
        title,
      }));
      setDndItems(newDndItems);
      setInitialDndItems(newDndItems); // Store the original order
    }
  }, [graphs]);

  if (isError) {
    const errorMessage =
      prepareError(error)?.status === HttpStatusCode.NOT_FOUND
        ? t('errorFetchAnalysisData')
        : t('errorCommon');
    return (
      <SiteHeaderWrapper siteId={siteId} siteName="...">
        <AlertWithRefetch onRetryClick={refetch}>{errorMessage}</AlertWithRefetch>
      </SiteHeaderWrapper>
    );
  }

  if (!graphs || isLoading || !dndItems.length) {
    return (
      <SiteHeaderWrapper siteId={siteId} siteName="...">
        <Spinner />
      </SiteHeaderWrapper>
    );
  }

  const sortedGraphs = [...graphs].sort((a, b) => {
    const indexA = dndItems.findIndex((item) => item.id === a.graphId);
    const indexB = dndItems.findIndex((item) => item.id === b.graphId);
    return indexA - indexB;
  });

  const handleItemsReordered = (newItems: DnDItem[]) => {
    setDndItems(newItems);
  };

  const handleReorderClick = () => {
    setIsReordering(true);
  };

  const handleCancelReorder = () => {
    setDndItems(initialDndItems);
    setIsReordering(false);
  };

  const handleSaveOrder = async () => {
    try {
      await updateOrder({
        siteId,
        items: sortedGraphs,
      }).unwrap();
      setIsReordering(false);
    } catch {
      notificationService.error(t('errorApplyGraphReorder'));
    }
  };

  const currentSite = currentUser?.sites.find(({ id }) => id === siteId);
  const pageTitle = currentSite ? currentSite.name : '-';

  return (
    <SiteHeaderWrapper siteId={siteId} siteName={pageTitle}>
      <DateTimeRangeProvider>
        {isReordering ? (
          <>
            <Flex justifyContent="flex-end" gap={1}>
              <ButtonPrimary
                disabled={updatingOrder}
                size="small"
                startIcon={updatingOrder ? <Spinner size={18} color="white" /> : <CheckIcon />}
                onClick={handleSaveOrder}
              >
                {t('buttonApplyOrder')}
              </ButtonPrimary>
              <ButtonPrimaryOutlined size="small" sx={{ mr: 1 }} onClick={handleCancelReorder}>
                {t('buttonCancel')}
              </ButtonPrimaryOutlined>
            </Flex>
            <ReorderGraphs items={dndItems} onReorder={handleItemsReordered} />
          </>
        ) : (
          <>
            <Flex justifyContent="flex-end" gap={1}>
              <ButtonPrimaryOutlined
                size="small"
                startIcon={<ReorderIcon />}
                onClick={handleReorderClick}
              >
                {t('buttonReorderGraphs')}
              </ButtonPrimaryOutlined>
              {isFeatureFlagEnabled(FEATURE_FLAGS.GRAPH_EDITOR) ? <GraphEditor /> : null}
            </Flex>
            <DateRangePanel />
            {sortedGraphs.map(({ graphId, title, dataLink }, index) => (
              <Accordion
                key={graphId}
                style={{ marginTop: 10, backgroundColor: 'white' }}
                defaultExpanded
                elevation={0}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <Typography variant="body1">{title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box marginTop="auto" marginBottom="auto">
                    <EchartWrapper
                      refs={refs}
                      groupRef={(el) => {
                        refs.current[index] = el;
                      }}
                      height={250}
                      deviceId={graphId}
                      dataLink={dataLink}
                      index={index}
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}
          </>
        )}
      </DateTimeRangeProvider>
    </SiteHeaderWrapper>
  );
};

export default DataAnalysis;
