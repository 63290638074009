import { ButtonBack } from '@components/Buttons/Buttons';
import { ConfiguredItem } from '@components/ConfigurationList/types';
import { TitleBar } from '@components/TitleBar/TitleBar';
import { t } from '@utils/translate';
import { paths } from 'paths';
import CreateUserForm from './CreateUserForm';

const CreateUser = () => (
  <>
    <ButtonBack path={paths.configuration} query={{ selectedItem: ConfiguredItem.USERS }}>
      {t('buttonBackToConfigurationList')}
    </ButtonBack>
    <TitleBar my={4} title={t('headingUserProfile')} titleVariant="h5" />
    <CreateUserForm />
  </>
);

export default CreateUser;
