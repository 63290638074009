import { ReactNode, useState } from 'react';
import { useGetSiteOptimizationQuery, useGetSiteQuery } from '@apiRtk/sites';
import { ApiErrorPayload } from '@appTypes/api/error';
import { UserSimpleDto } from '@appTypes/models/user.dto';
import { ButtonBack } from '@components/Buttons/Buttons';
import ConditionalWrapper from '@components/ConditionalWrapper/ConditionalWrapper';
import { ConfiguredItem } from '@components/ConfigurationList/types';
import { GapY } from '@components/LayoutUtils';
import { RelationsTabs } from '@components/RelationsForm/RelationsTabs';
import { SiteHeaderWrapper } from '@components/SiteHeaderWrapper/SiteHeaderWrapper';
import { useCurrentUserInfo, usePalette } from '@hooks';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Alert,
  CircularProgress,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import { t } from '@utils/translate';
import { paths } from 'paths';
import { matchPath, useLocation, useParams } from 'react-router-dom';
import SiteOptimizationForm from './SiteOptimizationForm';
import EcdList from './UpdateSite/Relations/EcdList';
import { EcdRelationModal } from './UpdateSite/Relations/EcdRelationModal';
import { EditEcdPermissionModal } from './UpdateSite/Relations/EditEcdPermissionModal';
import UserList from './UpdateSite/Relations/UserList';
import { UserRelationModal } from './UpdateSite/Relations/UserRelationModal';
import UpdateSiteForm from './UpdateSiteForm';

const UpdateSite = () => {
  const params = useParams();
  const id = params.id as string;
  const siteId = parseInt(id, 10);

  const location = useLocation();
  const isLocalSubpage = Boolean(matchPath('/sites/*', location.pathname));

  const tabs: ConfiguredItem[] = [ConfiguredItem.ECDS, ConfiguredItem.USERS];

  const [userRelationModalOpen, setUserRelationModalOpen] = useState(false);
  const [ecdRelationModalOpen, setEcdRelationModalOpen] = useState(false);
  const [editEcdPermissionModalOpen, setEditEcdPermissionModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<Nullable<UserSimpleDto>>(null);
  const [relationsTabVal, setRelationsTabVal] = useState(ConfiguredItem.ECDS);
  const [siteFormExpanded, setSiteFormExpanded] = useState(true);
  const [optimizationFormExpanded, setOptimizationFormExpanded] = useState(false);

  const { isWattstorUser, isSuperAdmin, isAdmin } = useCurrentUserInfo();

  const optimizationFormVisible = isWattstorUser;
  const optimizationFormEnabled = isWattstorUser && (isSuperAdmin || isAdmin);

  const {
    data: siteData,
    isLoading: siteLoading,
    error: siteError,
  } = useGetSiteQuery(siteId, {
    skip: !siteId || !siteFormExpanded,
  });

  const {
    data: siteOptimizationData,
    isLoading: siteOptimizationLoading,
    error: siteOptimizationError,
  } = useGetSiteOptimizationQuery(siteId, {
    skip: !siteId || !optimizationFormVisible || !optimizationFormExpanded,
  });
  const title = siteData?.name ? siteData.name : '-';

  const typedSiteError = siteError as ApiErrorPayload | undefined;
  const typedSiteOptimizationError = siteOptimizationError as ApiErrorPayload | undefined;
  const palette = usePalette();
  const dataErrorMessage = t('errorCommon');

  const siteErrorMessage =
    typedSiteError && typeof typedSiteError.data?.detail === 'string'
      ? typedSiteError.data.detail
      : dataErrorMessage;

  const siteOptimizationErrorMessage =
    typedSiteOptimizationError && typeof typedSiteOptimizationError.data?.detail === 'string'
      ? typedSiteOptimizationError.data.detail
      : dataErrorMessage;

  const handleOpenUserRelationModal = (user: UserSimpleDto) => {
    setSelectedUser(user);
    setEditEcdPermissionModalOpen(true);
  };

  if (Number.isNaN(siteId)) return null;

  const wrapperProps = { siteId, siteName: title };

  const wrapperFunction = (children: ReactNode) => (
    <SiteHeaderWrapper {...wrapperProps}>{children}</SiteHeaderWrapper>
  );

  return (
    <>
      {!isLocalSubpage ? (
        <>
          <ButtonBack path={paths.configuration}>{t('buttonBackToConfigurationList')}</ButtonBack>
          <GapY size={4} />
        </>
      ) : null}

      <ConditionalWrapper condition={isLocalSubpage} renderWrapper={wrapperFunction}>
        <Grid container spacing={{ xs: 4, lg: 10 }} px={0} pt={0}>
          <Grid item xs={12} md={12} lg={12} xl={5} pt={0}>
            <Accordion
              expanded={siteFormExpanded}
              onChange={() => setSiteFormExpanded(!siteFormExpanded)}
              sx={{ background: palette.common.white }}
              elevation={0}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{t('headingSiteDetails')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {siteLoading && <CircularProgress />}
                {siteError && !siteLoading ? (
                  <Alert severity="error">{siteErrorMessage}</Alert>
                ) : null}
                {siteData && !siteLoading && !siteError && (
                  <UpdateSiteForm siteId={siteId} siteData={siteData} />
                )}
              </AccordionDetails>
            </Accordion>

            <GapY size={1} />

            {isSuperAdmin && (
              <Accordion
                expanded={optimizationFormExpanded}
                onChange={() => setOptimizationFormExpanded(!optimizationFormExpanded)}
                sx={{ background: palette.common.white }}
                elevation={0}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{t('headingBenefitCalculation')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {siteOptimizationLoading && <CircularProgress />}
                  {siteOptimizationError && !siteOptimizationLoading ? (
                    <Alert severity="error">{siteOptimizationErrorMessage}</Alert>
                  ) : null}
                  {optimizationFormVisible &&
                    !siteOptimizationLoading &&
                    !siteOptimizationError && (
                      <SiteOptimizationForm
                        siteId={siteId}
                        data={siteOptimizationData}
                        formEnabled={optimizationFormEnabled}
                      />
                    )}
                </AccordionDetails>
              </Accordion>
            )}
          </Grid>

          <Grid item xs={12} md={12} lg={12} xl={7}>
            <RelationsTabs value={relationsTabVal} tabs={tabs} onValueChange={setRelationsTabVal} />
            {relationsTabVal === ConfiguredItem.ECDS && (
              <EcdList siteData={siteData} onAddRelatedECDs={() => setEcdRelationModalOpen(true)} />
            )}
            {relationsTabVal === ConfiguredItem.USERS && (
              <UserList
                siteData={siteData}
                onAddRelatedUser={() => setUserRelationModalOpen(true)}
                onEditEcdPermission={(user) => handleOpenUserRelationModal(user)}
              />
            )}
          </Grid>
        </Grid>

        <EcdRelationModal open={ecdRelationModalOpen} setOpen={setEcdRelationModalOpen} />
        <UserRelationModal open={userRelationModalOpen} setOpen={setUserRelationModalOpen} />
        <EditEcdPermissionModal
          user={selectedUser}
          open={editEcdPermissionModalOpen}
          setOpen={setEditEcdPermissionModalOpen}
        />
      </ConditionalWrapper>
    </>
  );
};

export default UpdateSite;
