import { ApiFetchListParams } from '@appTypes/helpers/api';
import { UserDto, UserSimpleDto, CreateUserDto, UpdateUserDto } from '@appTypes/models/user.dto';
import { UserFilters } from '@appTypes/users/filters';
import { apiCore } from './apiCore/apiCore';

const epHandler = apiCore.makeEndpointHandler<UserDto, UserSimpleDto>('users');

export const usersAPI = {
  getUsers(params: ApiFetchListParams<UserFilters>) {
    return epHandler.getAll('users', params);
  },
  getUser(id: UserDto['id']) {
    return epHandler.getSingle(id);
  },
  createUser(dto: CreateUserDto) {
    return epHandler.create({ ...dto, role: dto.role });
  },
  updateUser(dto: UpdateUserDto) {
    return epHandler.update({
      ...dto,
      role: dto.role,
    });
  },
  deactivateUser(id: UserDto['id']) {
    return epHandler.deleteItem(id);
  },
};
