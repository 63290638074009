import { AnalyticsDto, SiteDto } from '@appTypes/models/site.dto';
import { coreApi } from './core';

type AnalyticsArgs = {
  items: AnalyticsDto[];
  siteId: SiteDto['id'];
};

const analyticsApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getAnalytics: builder.query<AnalyticsDto[], AnalyticsArgs['siteId']>({
      query: (siteId) => `sites/${siteId}/analytics`,
    }),
    updateOrder: builder.mutation<AnalyticsDto[], AnalyticsArgs>({
      query: ({ siteId, items }) => ({
        url: `sites/${siteId}/analytics`,
        method: 'PUT',
        data: items,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetAnalyticsQuery, useUpdateOrderMutation } = analyticsApi;
