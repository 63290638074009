import { useRedirect } from '@hooks';
import { RedirectBackRules } from '@hooks/useRedirectBack';
import { AddRounded, ArrowBack, EditRounded } from '@mui/icons-material';

import Button, { ButtonProps } from '@mui/material/Button';
import { QueryParams } from '@utils/routing/createQueryParamsStr';
import { t } from '@utils/translate';
import { palette } from 'styles/palette';

export const ButtonPrimary = ({ children, ...props }: ButtonProps) => (
  <Button variant="contained" {...props}>
    {children}
  </Button>
);

export const ButtonPrimaryOutlined = ({ children, ...props }: ButtonProps) => (
  <Button variant="outlined" {...props}>
    {children}
  </Button>
);

export const ButtonSecondary = ({ children, ...props }: ButtonProps) => (
  <Button variant="contained" color="secondary" {...props}>
    {children}
  </Button>
);

export const ButtonAdd = ({ children, ...props }: ButtonProps) => (
  <ButtonPrimary endIcon={<AddRounded />} {...props}>
    {children}
  </ButtonPrimary>
);

export const ButtonDetail = ({ children, ...props }: ButtonProps) => (
  <ButtonPrimaryOutlined size="small" {...props}>
    {children || t('buttonDetail')}
  </ButtonPrimaryOutlined>
);

export const ButtonEdit = ({ children, sx, ...props }: ButtonProps) => (
  <Button
    variant="text"
    size="small"
    sx={{ textTransform: 'uppercase', ...sx }}
    startIcon={<EditRounded />}
    {...props}
  >
    {children || t('buttonEdit')}
  </Button>
);

export const ButtonCancel = ({ children, sx, ...props }: ButtonProps) => (
  <Button
    variant="text"
    size="large"
    color="info"
    sx={{ textTransform: 'uppercase', color: palette.neutral.dark, ...sx }}
    {...props}
  >
    {children || t('buttonCancel')}
  </Button>
);

export const ButtonRed = ({ children, sx, ...props }: ButtonProps) => (
  <Button
    variant="text"
    size="large"
    color="error"
    sx={{ textTransform: 'uppercase', color: palette.neutral.dark, ...sx }}
    {...props}
  >
    {children}
  </Button>
);

export const ButtonSave = ({ children, sx, ...props }: ButtonProps) => (
  <ButtonPrimary size="large" sx={{ textTransform: 'uppercase', ...sx }} {...props}>
    {children || t('buttonSave')}
  </ButtonPrimary>
);

export const ButtonBack = ({
  path,
  onClick,
  query,
  noPreviousFallback,
  forbiddenPaths,
  sx,
  ...buttonProps
}: ButtonBackProps) => {
  const redirect = useRedirect();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else if (path) {
      redirect(() => path, { query });
    }
  };

  return (
    <Button
      variant="text"
      sx={{ textTransform: 'uppercase', color: palette.neutral.dark, ...sx }}
      startIcon={<ArrowBack />}
      onClick={handleClick}
      {...buttonProps}
    />
  );
};

type ButtonBackProps = {
  path?: string;
  query?: QueryParams;
  onClick?: () => void;
} & ButtonProps &
  Pick<RedirectBackRules, 'forbiddenPaths' | 'noPreviousFallback'>;

export const ButtonText = ({ children, sx, color = 'error', ...props }: ButtonProps) => (
  <Button
    variant="text"
    size="small"
    color={color}
    sx={{ textTransform: 'uppercase', ...sx }}
    {...props}
  >
    {children}
  </Button>
);
