import { useGetSitesQuery } from '@apiRtk/sites';
import { FilterParamsCommonDto } from '@appTypes/models/common.dto';
import { SiteDto, SiteSimpleDto } from '@appTypes/models/site.dto';
import AlertWithRefetch from '@components/AlertWithRefetch/AlertWithRefetch';
import { ButtonPrimaryOutlined } from '@components/Buttons/Buttons';
import { Spinner } from '@components/Spinner';
import TanStackTable from '@components/TanStackTable/TanStackTable';
import { useRedirect } from '@hooks';
import { Box } from '@mui/material';
import {
  ColumnDef,
  OnChangeFn,
  PaginationState,
  SortingState,
  Updater,
} from '@tanstack/react-table';
import { t } from '@utils/translate';

const createColumns = (
  text: string,
  onClick: (siteId: SiteSimpleDto['id'], redirect: ReturnType<typeof useRedirect>) => void,
  redirect: ReturnType<typeof useRedirect>,
): ColumnDef<SiteDto>[] => [
  {
    header: t('headerName'),
    accessorKey: 'name',
    cell: ({ row }) => <div style={{ flex: 0.5 }}>{row.original.name}</div>,
  },
  {
    id: 'organization.name',
    header: t('headerOrganization'),
    accessorKey: 'organization.name',
    cell: ({ row }) => <div style={{ flex: 0.5 }}>{row.original.organization?.name}</div>,
  },
  {
    header: t('headerCity'),
    accessorKey: 'city',
    cell: ({ row }) => <div style={{ flex: 0.5 }}>{row.original.address.city}</div>,
  },
  {
    header: t('headerCountry'),
    accessorKey: 'country',
    cell: ({ row }) => (
      <div style={{ minWidth: 250 }}>
        <Box width="100%" display="grid" alignItems="center" gridTemplateColumns="auto 110px">
          {row.original.address.country || <div />}
          <ButtonPrimaryOutlined size="small" onClick={() => onClick(row.original.id, redirect)}>
            {text}
          </ButtonPrimaryOutlined>
        </Box>
      </div>
    ),
  },
];

export type SiteListProps = {
  buttonText: string;
  filters: FilterParamsCommonDto;
  sorting: SortingState;
  pagination: PaginationState;
  onClick: (siteId: SiteSimpleDto['id'], redirect: ReturnType<typeof useRedirect>) => void;
  onSortingChange: (sorting: Updater<SortingState>) => void;
  onPaginationChange: OnChangeFn<PaginationState>;
};

export const SiteList = ({
  buttonText,
  filters,
  sorting,
  pagination,
  onClick,
  onSortingChange,
  onPaginationChange,
}: SiteListProps) => {
  const redirect = useRedirect();

  const columns = createColumns(buttonText, onClick, redirect);

  const { data, isError, refetch, isLoading, isFetching } = useGetSitesQuery(filters, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <>
      {(isLoading || isFetching) && <Spinner />}
      {isError && <AlertWithRefetch onRetryClick={refetch}>{t('errorGetSites')}</AlertWithRefetch>}
      {data && !isLoading && !isFetching && (
        <TanStackTable<SiteDto>
          columns={columns}
          rows={data.sites || []}
          itemsCount={data.count || 0}
          sorting={sorting}
          pagination={pagination}
          onSortingChange={onSortingChange}
          onPaginationChange={onPaginationChange}
        />
      )}
    </>
  );
};
