import { useMoveSiteToOrganizationMutation } from '@apiRtk/sites';
import SiteSelectOrganization from '@components/Form/SiteSelectOrganization';
import FormModal from '@components/Modals/FormModal';
import { notificationService } from '@services/notificationService/notificationService';
import { t } from '@utils/translate';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

type FormValues = {
  siteId: Nullable<number>;
};

const defaultValues = {
  siteId: null,
};

interface SiteRelationModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SiteRelationModal = ({ open, setOpen }: SiteRelationModalProps) => {
  const params = useParams();
  const organizationId = parseInt(params.id!, 10);

  const [moveSiteToOrganization, { isLoading }] = useMoveSiteToOrganizationMutation();

  const { handleSubmit, control, setValue, formState, reset } = useForm<FormValues>({
    defaultValues,
  });

  const handleFormSubmit: SubmitHandler<FormValues> = async (fieldValues) => {
    const { siteId } = fieldValues;

    if (!siteId) {
      return;
    }

    try {
      await moveSiteToOrganization({
        siteId,
        organization_id: organizationId,
      }).unwrap();

      setOpen(false);
      reset();
      notificationService.success(t('successSiteAdded'));
    } catch (err) {
      notificationService.error(t('errorAddSiteToOrganization'));
    }
  };

  return (
    <FormModal
      open={open}
      setOpen={setOpen}
      dialogTitle={t('dialogTitleAddRelatedSite')}
      onSubmit={handleSubmit(handleFormSubmit)}
      submitDisabled={!formState.isValid || isLoading}
    >
      <SiteSelectOrganization
        organizationId={organizationId}
        name="siteId"
        control={control}
        setValue={setValue}
        required
      />
    </FormModal>
  );
};
