import { useGetEnergyFlowQuery } from '@apiRtk/imps';
import CONFIG from '@config';
import { useVisibilityChange } from '@hooks';
import { Alert, Box } from '@mui/material';
import { t } from '@utils/translate';
import { Diagram } from './EnergyFlow/index';

const EnergyFlow = ({ ecdId }: { ecdId: string }) => {
  const documentVisible = useVisibilityChange();

  const { data, isError, isLoading } = useGetEnergyFlowQuery(ecdId!, {
    skip: !ecdId || !documentVisible,
    pollingInterval: CONFIG.ENERGY_FLOW.DIAGRAM_REFRESH_INTERVAL,
  });

  if (isError && !isLoading) {
    return (
      <Box p={5}>
        <Alert severity="error">{t('errorLiveDiagramDataNotAvailable')}</Alert>
      </Box>
    );
  }

  return <Diagram config={CONFIG.ENERGY_FLOW.DEVICES_CONFIG} loading={isLoading} data={data} />;
};

export default EnergyFlow;
