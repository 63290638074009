import { PropsWithChildren, useState } from 'react';
import { useGetPerformanceOverTimeQuery } from '@apiRtk/performanceOverTime';
import AlertWithRefetch from '@components/AlertWithRefetch/AlertWithRefetch';
import { Flex } from '@components/LayoutUtils';
import { Grid, Typography } from '@mui/material';
import CONFIG from '@settings/config';
import { t } from '@utils/translate';
import DataExport from '../DataExport/DataExport';
import CardBattery from './CardBattery';
import CardConsumption from './CardConsumption';
import CardPriceOptimisationBenefit from './CardPriceOptimisationBenefit';
import CardRenewables from './CardRenewables';
import { DateRangeDisplay } from './DateRangeDisplay';
import PeriodPicker, { periods } from './PeriodPicker';

const PerformanceOverTime = ({ siteId }: { siteId: number }) => {
  type DateRange = {
    start: Date;
    end: Date;
  };

  const [datetimeRange, setDatetimeRange] = useState<DateRange>({
    start: periods[0].start,
    end: periods[0].end,
  });

  const [isSolarActive, setIsSolarActive] = useState(false);

  const toggleSolarProduction = () => {
    setIsSolarActive((prev) => !prev);
  };

  const { error, isLoading, isFetching, data, refetch } = useGetPerformanceOverTimeQuery(
    {
      siteId,
      startDate: datetimeRange.start,
      endDate: datetimeRange.end,
      count: CONFIG.CARDS.GRAPHS_DATAPOINTS_COUNT,
      solarBaseline: isSolarActive,
    },
    {
      skip: !siteId,
    },
  );

  const loading = isLoading || isFetching || !data;
  const showCardBenefitCalculation = !!data?.savings;

  const handleChange = (start: Date, end: Date) => {
    setDatetimeRange({ start, end });
  };

  const heading = (
    <>
      <Typography variant="h5" pt={4} pb={2}>
        {t('headingPerformanceOverTime')}
      </Typography>
      <Flex cX justifyContent="space-between" flexWrap="wrap" gap={1} pb={2}>
        <PeriodPicker onChange={handleChange} />
        <DateRangeDisplay start={datetimeRange.start} end={datetimeRange.end} />
        <DataExport />
      </Flex>
    </>
  );

  if (error) {
    return (
      <>
        {heading}
        <AlertWithRefetch severity="error" onRetryClick={refetch}>
          {t('errorPerformanceOverTimeNoData')}
        </AlertWithRefetch>
      </>
    );
  }

  return (
    <>
      {heading}
      <Grid container spacing={2}>
        {showCardBenefitCalculation && (
          <GridItem>
            <CardPriceOptimisationBenefit
              loading={loading}
              data={data?.savings}
              isSolarActive={isSolarActive}
              onToggleSolar={toggleSolarProduction}
            />
          </GridItem>
        )}
        <GridItem>
          <CardRenewables data={data?.renewables} loading={loading} />
        </GridItem>
        <GridItem>
          <CardBattery data={data?.battery} loading={loading} />
        </GridItem>
        <GridItem>
          <CardConsumption data={data?.consumption} loading={loading} />
        </GridItem>
      </Grid>
    </>
  );
};

function GridItem({ children }: PropsWithChildren) {
  return (
    <Grid item xs={12} sm={6} md={6} sx={{ minWidth: '356px' }}>
      {children}
    </Grid>
  );
}

export default PerformanceOverTime;
