import { t } from '@utils/translate';
import {
  subDays,
  startOfToday,
  startOfYesterday,
  endOfYesterday,
  startOfWeek,
  subWeeks,
  endOfWeek,
  startOfMonth,
  subMonths,
  endOfMonth,
  subHours,
} from 'date-fns';
import { createStaticRanges } from 'react-date-range';

const chartRangePresets = [
  {
    label: t('rangePreset1Hr'),
    inHotbar: true,
    range: () => {
      const now = new Date();
      return {
        startDate: subHours(now, 1),
        endDate: now,
      };
    },
  },
  {
    label: t('rangePreset6Hrs'),
    inHotbar: true,
    range: () => {
      const now = new Date();
      return {
        startDate: subHours(now, 6),
        endDate: now,
      };
    },
  },
  {
    label: t('rangePreset12Hrs'),
    inHotbar: true,
    range: () => {
      const now = new Date();
      return {
        startDate: subHours(now, 12),
        endDate: now,
      };
    },
  },
  {
    label: t('rangePreset24Hrs'),
    inHotbar: true,
    range: () => {
      const now = new Date();
      return {
        startDate: subDays(now, 1),
        endDate: now,
      };
    },
  },
  {
    label: t('rangePreset7Days'),
    range: () => {
      const now = new Date();
      return {
        startDate: subDays(now, 7),
        endDate: now,
      };
    },
  },
  {
    label: t('rangePreset30Days'),
    range: () => {
      const now = new Date();
      return {
        startDate: subDays(now, 30),
        endDate: now,
      };
    },
  },
  {
    label: t('rangePreset90Days'),
    range: () => {
      const now = new Date();
      return {
        startDate: subDays(now, 90),
        endDate: now,
      };
    },
  },
  {
    label: t('rangePresetToday'),
    range: () => {
      const now = new Date();
      return {
        startDate: startOfToday(),
        endDate: now,
      };
    },
  },
  {
    label: t('rangePresetYesterday'),
    range: () => ({
      startDate: startOfYesterday(),
      endDate: endOfYesterday(),
    }),
  },
  {
    label: t('rangePresetThisWeek'),
    range: () => {
      const now = new Date();
      return {
        startDate: startOfWeek(now),
        endDate: now,
      };
    },
  },
  {
    label: t('rangePresetPrevWeek'),
    range: () => {
      const now = new Date();
      const lastWeekStart = startOfWeek(subWeeks(now, 1));
      const lastWeekEnd = endOfWeek(subWeeks(now, 1));
      const endDate = lastWeekEnd < now ? lastWeekEnd : now;
      return {
        startDate: lastWeekStart,
        endDate,
      };
    },
  },
  {
    label: t('rangePresetThisMonth'),
    range: () => {
      const now = new Date();
      return {
        startDate: startOfMonth(now),
        endDate: now,
      };
    },
  },
  {
    label: t('rangePresetPrevMonth'),
    range: () => {
      const now = new Date();
      const lastMonthStart = startOfMonth(subMonths(now, 1));
      const lastMonthEnd = endOfMonth(subMonths(now, 1));
      const endDate = lastMonthEnd < now ? lastMonthEnd : now;
      return {
        startDate: lastMonthStart,
        endDate,
      };
    },
  },
].map((item) => ({ inHotbar: false, ...item }));

export const hotbarPresets = chartRangePresets.filter((preset) => preset.inHotbar);
const rangePickerPresets = chartRangePresets.filter((preset) => !preset.inHotbar);

export const staticRanges = createStaticRanges(rangePickerPresets);
