import { dictionary, DictionaryKeys } from '@settings/dictionary';

/**
 * Translation function
 *   - `key` must be one of the known keys in `dictionary`.
 *   - `params` is an object used to replace {{placeholders}}
 */
export function t(key: DictionaryKeys, params?: Record<string, any>): string {
  let text = dictionary[key];

  if (!text) {
    return key;
  }

  if (params) {
    Object.entries(params).forEach(([placeholder, value]) => {
      text = text.replace(new RegExp(`{{${placeholder}}}`, 'g'), String(value));
    });
  }

  return text;
}
