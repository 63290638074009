import styled from '@emotion/styled';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Header, flexRender } from '@tanstack/react-table';
import { t } from '@utils/translate';
import { palette } from 'styles/palette';

interface StyledButtonProps {
  canSort: boolean;
}

const StyledButton = styled.button<StyledButtonProps>`
  color: ${({ canSort }) => (canSort ? palette.primary.main : palette.primary.dark)};
  display: flex;
  gap: 3px;
  align-items: center;
  cursor: ${({ canSort }) => (canSort ? 'pointer' : 'default')};
  padding: 0;
  border: none;
  background: none;
  font-size: 14px;
`;

interface HeaderButtonProps<T> {
  header: Header<T, unknown>;
}

const HeaderButton = <T,>({ header }: HeaderButtonProps<T>) => {
  const getHeaderTitle = (siteHeader: Header<T, unknown>) => {
    if (!siteHeader.column.getCanSort()) {
      return undefined;
    }

    if (siteHeader.column.getNextSortingOrder() === 'asc') {
      return t('labelSortAscending');
    }
    if (siteHeader.column.getNextSortingOrder() === 'desc') {
      return t('labelSortDescending');
    }

    return t('labelClearSort');
  };

  return (
    <StyledButton
      data-cy="list-header-item"
      type="button"
      canSort={header.column.getCanSort()}
      onClick={header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined}
      title={getHeaderTitle(header)}
    >
      {flexRender(header.column.columnDef.header, header.getContext())}
      {{
        asc: <ArrowUpward style={{ fontSize: 14 }} />,
        desc: <ArrowDownward style={{ fontSize: 14 }} />,
      }[header.column.getIsSorted() as string] ?? null}
    </StyledButton>
  );
};

export default HeaderButton;
