import React from 'react';
import { ZoomIn, ZoomOut } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { t } from '@utils/translate';
import { differenceInMilliseconds, addMilliseconds, subMilliseconds, min } from 'date-fns';

interface ZoomButtonsProps {
  startDate: Date;
  endDate: Date;
  onChange: (newStartDate: Date, newEndDate: Date) => void;
  zoomFactor?: number;
}

const ZoomButtons = ({ startDate, endDate, onChange, zoomFactor = 0.5 }: ZoomButtonsProps) => {
  const now = new Date();

  const minRangeInMs = 15 * 60 * 1000; // 15 minutes in milliseconds
  const maxRangeInMs = 365 * 24 * 60 * 60 * 1000; // 1 year in milliseconds

  const currentRangeInMs = differenceInMilliseconds(endDate, startDate);

  const canZoomIn = currentRangeInMs > minRangeInMs;
  const canZoomOut = currentRangeInMs < maxRangeInMs;

  const handleZoomIn = () => {
    if (!canZoomIn) return;

    const rangeAdjustment = currentRangeInMs * zoomFactor;
    const newStartDate = addMilliseconds(startDate, rangeAdjustment / 2);
    const newEndDate = subMilliseconds(endDate, rangeAdjustment / 2);

    // Ensure minimum range limit
    const newRangeInMs = differenceInMilliseconds(newEndDate, newStartDate);
    if (newRangeInMs < minRangeInMs) {
      onChange(addMilliseconds(endDate, -minRangeInMs), endDate);
      return;
    }

    onChange(newStartDate, newEndDate);
  };

  const handleZoomOut = () => {
    if (!canZoomOut) return;

    const rangeAdjustment = currentRangeInMs * zoomFactor;
    let newStartDate = subMilliseconds(startDate, rangeAdjustment / 2);
    let newEndDate = addMilliseconds(endDate, rangeAdjustment / 2);

    // Ensure endDate does not go into the future
    newEndDate = min([newEndDate, now]);

    // Adjust startDate if endDate was adjusted to now
    const adjustedRangeInMs = differenceInMilliseconds(newEndDate, newStartDate);
    if (adjustedRangeInMs > maxRangeInMs) {
      newStartDate = subMilliseconds(newEndDate, maxRangeInMs);
    }

    onChange(newStartDate, newEndDate);
  };

  return (
    <div>
      <IconButton
        onClick={handleZoomOut}
        color="primary"
        disabled={!canZoomOut}
        aria-label={t('buttonZoomOut')}
      >
        <ZoomOut />
      </IconButton>
      <IconButton
        onClick={handleZoomIn}
        color="primary"
        disabled={!canZoomIn}
        aria-label={t('buttonZoomIn')}
      >
        <ZoomIn />
      </IconButton>
    </div>
  );
};

export default ZoomButtons;
