import { useEffect } from 'react';
import { useAddSiteRelatedUserMutation, useUpdateUserPermissionsMutation } from '@apiRtk/sites';
import { ApiErrorPayload } from '@appTypes/api/error';
import { EcdDevicesPermissions } from '@appTypes/models/site.dto';
import { UserSimpleDto } from '@appTypes/models/user.dto';
import { GapY } from '@components/LayoutUtils';
import FormModal from '@components/Modals/FormModal';
import { Select, MenuItem } from '@mui/material';
import { notificationService } from '@services/notificationService/notificationService';
import { EcdPermissionTitles } from '@utils/data/EcdPermissionTitles';
import { t } from '@utils/translate';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import UserLine from './EditEcdPermissionModal/UserLine';

const ecdDevicesPermissionsItems = Object.entries(EcdPermissionTitles).map(([key, label]) => ({
  value: key as EcdDevicesPermissions,
  label,
}));

type FormValues = {
  permissions: EcdDevicesPermissions;
};

const defaultValues = {
  permissions: EcdDevicesPermissions.VIEW,
};

export const EditEcdPermissionModal = ({
  user,
  open,
  setOpen,
}: {
  user: Nullable<UserSimpleDto>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [updateUserPermissions, { isLoading: isUpdateUserLoading }] =
    useUpdateUserPermissionsMutation();

  const [addSiteRelatedUser, { isLoading: isAddSiteUserLoading }] = useAddSiteRelatedUserMutation();

  const params = useParams();
  const siteId = parseInt(params.id!, 10);

  const { handleSubmit, control, formState, reset } = useForm<FormValues>({
    defaultValues,
  });

  useEffect(() => {
    if (user && open) {
      reset({
        permissions: user.device_permission || EcdDevicesPermissions.VIEW,
      });
    }
  }, [user, open, reset]);

  const handleFormSubmit: SubmitHandler<FormValues> = async (fieldValues) => {
    const { permissions } = fieldValues;

    if (!user?.id) {
      notificationService.error(t('errorNoUserSelected'));
      return;
    }

    try {
      const mutation = user.device_permission
        ? updateUserPermissions({
            siteId,
            user_id: user.id,
            device_permission: permissions,
          }).unwrap()
        : addSiteRelatedUser({
            id: siteId,
            user_id: user.id,
            devices_permissions: permissions,
          }).unwrap();

      await mutation;
      setOpen(false);
      notificationService.success(t('successUserPermissionsSet'));
    } catch (err) {
      const typedError = err as ApiErrorPayload;
      notificationService.error(
        typedError && typeof typedError?.data?.detail === 'string'
          ? typedError.data.detail
          : t('errorAddUserToSite'),
      );
    }
  };

  return (
    <FormModal
      open={open}
      setOpen={setOpen}
      dialogTitle={t('dialogTitleSetEcdPermission')}
      onSubmit={handleSubmit(handleFormSubmit)}
      submitDisabled={!formState.isValid || isUpdateUserLoading || isAddSiteUserLoading}
    >
      <UserLine user={user} />
      <GapY size={3} />
      <Controller
        name="permissions"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Select
            size="medium"
            label={t('labelEcdDevicesPermissions')}
            value={value || ''}
            fullWidth
            onChange={onChange}
            sx={{ minWidth: '400px' }}
          >
            {ecdDevicesPermissionsItems.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormModal>
  );
};
