import { EcdsData, useGetEcdsForSelectQuery } from '@apiRtk/imps';
import { useGetSiteQuery } from '@apiRtk/sites';
import { SiteDto } from '@appTypes/models/site.dto';
import { CenterX } from '@components/LayoutUtils';
import { Spinner } from '@components/Spinner';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { t } from '@utils/translate';
import { Control, Controller } from 'react-hook-form';

const renderEcdSelectItem = (ecdData: EcdsData[0]) => {
  const { id, name } = ecdData;

  return (
    <CenterX gap={1} justifyItems="space-between">
      <Box>
        <Typography>{id}</Typography>
        <Typography variant="body2" color="GrayText">
          {name}
        </Typography>
      </Box>
    </CenterX>
  );
};

type EcdSelectProps = {
  siteId: number;
  name: string;
  control: Control<any>;
  setValue: any;
  required?: boolean;
};

const EcdSelect = ({ siteId, control, setValue, required, name }: EcdSelectProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { data: ecdsData, isLoading } = useGetEcdsForSelectQuery();
  const { data: siteData, isLoading: isSiteLoading } = useGetSiteQuery(siteId);

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField {...params} label={t('labelSearchEcd')} fullWidth />
  );

  if (isLoading || isSiteLoading || !ecdsData || !siteData) {
    return <Spinner size={15} />;
  }

  const getFilteredOptions = (ecds: EcdsData, site: SiteDto): EcdsData => {
    const ecdsToFilter = site.imps;
    return ecds.filter((ecd) => !ecdsToFilter.some((filteredEcd) => filteredEcd.id === ecd.id));
  };

  return (
    <Box sx={{ width: isMobile ? '100%' : '400px' }}>
      <Controller
        name={name}
        control={control}
        defaultValue={null}
        rules={{ required }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            options={getFilteredOptions(ecdsData, siteData)}
            onChange={(_, val) => {
              setValue(name, val?.id || null, { shouldValidate: true });
            }}
            onBlur={() => {
              if (!field.value) {
                setValue(name, null, { shouldValidate: true });
              }
            }}
            value={ecdsData.find((profile) => profile.id === field.value) || null}
            getOptionLabel={(option) => `${option.id} (${option.name})`}
            renderInput={(params) => renderInput(params)}
            renderOption={(props, ecdData) => {
              const { key, ...rest } = props;
              return (
                <li key={key} {...rest}>
                  {renderEcdSelectItem(ecdData)}
                </li>
              );
            }}
            fullWidth
          />
        )}
      />
    </Box>
  );
};

export default EcdSelect;
