import { useUpdateNotificationRuleMutation } from '@apiRtk/notificationRules';
import {
  NotificationRuleDto,
  UpdateNotificationRuleDto,
} from '@appTypes/models/notificationRule.dto';
import { Switch } from '@components/Switch';
import { useMutationAlerts } from '@hooks';
import { Box } from '@mui/material';
import { t } from '@utils/translate';

interface DeviceCellProps {
  formattedValue: string;
  siteId: number;
  isActive: boolean;
  row: NotificationRuleDto;
}

export const DeviceCell = ({ formattedValue, siteId, isActive, row }: DeviceCellProps) => {
  const [updateNotificationRule, updateResult] = useUpdateNotificationRuleMutation();

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const updateData: UpdateNotificationRuleDto = {
      id: row.id,
      comparison_field: row.comparison_field,
      condition_comparison_operator: row.condition_comparison_operator,
      condition_value: row.condition_value,
      description: row.description,
      device: row.device,
      emails_list: row.emails_list,
      site_id: siteId,
      is_active: checked,
    };

    updateNotificationRule(updateData);
  };

  useMutationAlerts({
    mutationResult: updateResult,
    messageSuccess: t('successRuleToggle', { status: isActive }),
    messageError: t('errorToggleRule'),
  });

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Switch
        sx={{ mr: 1 }}
        size="small"
        checked={isActive}
        onChange={handleSwitchChange}
        disabled={updateResult.isLoading}
      />
      <span>{formattedValue === 'Calculator' ? t('labelDeviceFlows') : formattedValue}</span>
    </Box>
  );
};
