import { useEffect } from 'react';
import { useAddEcdToSiteMutation } from '@apiRtk/imps';
import EcdSelect from '@components/Form/EcdSelect';
import FormModal from '@components/Modals/FormModal';
import { Alert } from '@mui/material';
import { createAlert } from '@redux/ducks/alerts/actionCreators';
import { t } from '@utils/translate';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

type FormValues = {
  ecdId: Nullable<string>;
};

const defaultValues = {
  ecdId: null,
};

export const EcdRelationModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [addEcdToSite, { isLoading, isSuccess, isError }] = useAddEcdToSiteMutation();
  const params = useParams();
  const siteId = parseInt(params.id!, 10);

  const dispatch = useDispatch();

  const { handleSubmit, control, setValue, formState, reset } = useForm<FormValues>({
    defaultValues,
  });

  useEffect(() => {
    if (isSuccess) {
      setOpen(false);
      dispatch(
        createAlert({
          message: t('successEcdAdded'),
          variant: 'success',
        }),
      );
      reset();
    }
  }, [dispatch, isSuccess, reset, setOpen, siteId]);

  const handleFormSubmit: SubmitHandler<FormValues> = (fieldValues) => {
    const { ecdId } = fieldValues;

    addEcdToSite({
      id: ecdId || '',
      site_id: siteId,
    });
  };

  return (
    <FormModal
      open={open}
      setOpen={setOpen}
      dialogTitle={t('dialogTitleAddRelatedEcd')}
      onSubmit={handleSubmit(handleFormSubmit)}
      submitDisabled={!formState.isValid || isLoading}
    >
      {isError ? (
        <Alert severity="error" sx={{ mb: 2 }}>
          {t('errorAddEcdToSite')}
        </Alert>
      ) : null}

      <EcdSelect siteId={siteId} name="ecdId" control={control} setValue={setValue} required />
    </FormModal>
  );
};
