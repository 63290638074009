import { PropsWithChildren } from 'react';
import { ButtonCancel, ButtonSave } from '@components/Buttons/Buttons';
import { CenterX, Flex } from '@components/LayoutUtils';
import { Close } from '@mui/icons-material';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { t } from '@utils/translate';

type ConfirmModalProps = PropsWithChildren<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dialogTitle?: string;
  onSubmit?: () => void;
  onCancel?: () => void;
  confirmButtonCaption?: string;
  cancelButtonCaption?: string;
  noCancelButton?: boolean;
  submitDisabled?: boolean;
  forceFullscreen?: boolean;
}>;

const FormModal = ({
  open,
  setOpen,
  children,
  dialogTitle,
  onSubmit,
  onCancel,
  confirmButtonCaption,
  cancelButtonCaption,
  noCancelButton,
  submitDisabled,
  forceFullscreen,
}: ConfirmModalProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md')) || forceFullscreen;

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    onSubmit?.();
  };

  const handleCancel = () => {
    onCancel?.();
    setOpen(false);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      sx={{
        zIndex: 1300,
        '& .MuiPaper-root': {
          minWidth: '448px',
        },
      }}
      onClose={handleClose}
    >
      {dialogTitle ? (
        <>
          <CenterX pl={3} pr={1} py={1} justifyContent="space-between">
            <Typography variant="body1" fontWeight="bold">
              {dialogTitle}
            </Typography>
            <IconButton color="primary" onClick={handleClose}>
              <Close />
            </IconButton>
          </CenterX>
          <Divider />
        </>
      ) : null}
      <Box px={3} py={2}>
        {children}
        <Flex p={0} pt={2} gap={1} justifyContent="flex-end">
          <ButtonSave
            data-testid="form-submit"
            type="submit"
            onClick={handleConfirm}
            autoFocus
            disabled={submitDisabled}
          >
            {confirmButtonCaption || t('buttonConfirm')}
          </ButtonSave>
          {noCancelButton ? null : (
            <ButtonCancel onClick={handleCancel}>
              {cancelButtonCaption || t('buttonCancel')}
            </ButtonCancel>
          )}
        </Flex>
      </Box>
    </Dialog>
  );
};

export default FormModal;
