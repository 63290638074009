import { PerformanceOverTimeDto } from '@appTypes/models/cards.dto';
import CardAlignedItem from '@components/Cards/CardAlignedItem';
import CardHead from '@components/Cards/CardHead';
import CardListItem from '@components/Cards/CardListItem';
import CardSummaryItem from '@components/Cards/CardSummaryItem';
import { CardContentWithLoader } from '@components/Cards/CardWithLoader';
import { CardContainer } from '@components/Cards/components';
import { GapX, GapY } from '@components/LayoutUtils';
import { ReactComponent as IconArrowIn } from '@icons/arrow-in.svg';
import { ReactComponent as IconArrowOut } from '@icons/arrow-out.svg';
import { ReactComponent as IconBattery } from '@icons/battery.svg';
import { Grid, Box } from '@mui/material';
import CONFIG from '@settings/config';
import { t } from '@utils/translate';
import { CardTooltip } from './CardTooltip';
import { ComingSoonMedium, ComingSoonSmall } from './ComingSoonOverlay';

const keyToTitleMap: Record<string, string> = {
  'from-grid': t('labelFromGrid'),
  'from-renewables': t('labelFromRenewables'),
};

interface CardBatteryProps {
  data?: PerformanceOverTimeDto['battery'];
  loading?: boolean;
}

const CardBattery = ({ data, loading }: CardBatteryProps) => (
  <CardContainer>
    <CardHead
      title={t('headingBatteryStorage')}
      subtitle={t('labelStateOfYourBattery')}
      icon={<IconBattery />}
      rightSlot={
        <>
          <CardTooltip textKey="batteryHealth" />
          <GapX />
        </>
      }
    />
    <CardContentWithLoader loading={loading}>
      {data ? (
        <>
          <Grid container pb={3}>
            <Grid item xs={6}>
              <CardSummaryItem value={data.cyclesAverage} title={t('labelCyclesDayAverage')} />
            </Grid>

            {CONFIG.CARDS.COMING_SOON_LABELS ? (
              <ComingSoonMedium featureName={t('labelTotalCycles')} />
            ) : (
              <Grid item xs={6}>
                <CardSummaryItem value={data.cyclesTotal} title={t('labelTotalCycles')} />
              </Grid>
            )}
          </Grid>
          <Box py={1}>
            <CardAlignedItem
              value={data.chargeTotal}
              caption={`${t('labelWhTotalCharge')}`}
              icon={<IconArrowIn />}
            />

            {CONFIG.CARDS.COMING_SOON_LABELS ? (
              <Box ml="78px">
                <ComingSoonSmall />
              </Box>
            ) : (
              data.chargeItems.map(({ key, value }) => (
                <CardAlignedItem
                  key={key}
                  value={value}
                  caption={`Wh ${keyToTitleMap[key] || key}`}
                  subItem
                />
              ))
            )}

            <GapY size={2} />

            <CardAlignedItem
              value={data.dischargeTotal}
              icon={<IconArrowOut />}
              caption={`${t('labelWhTotalDischarge')}`}
            />

            {data.dischargeItems.map(({ key, value }) => (
              <CardListItem
                key={key}
                value={value}
                suffix={`Wh ${keyToTitleMap[key] || key}`}
                subItem
              />
            ))}
          </Box>
        </>
      ) : (
        <Box sx={{ height: '100px' }} />
      )}
    </CardContentWithLoader>
  </CardContainer>
);

export default CardBattery;
