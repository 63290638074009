import { useGetOrganizationsQuery } from '@apiRtk/organizations';
import { FilterParamsCommonDto } from '@appTypes/models/common.dto';
import { OrganizationSimpleDto } from '@appTypes/models/organization.dto';
import AlertWithRefetch from '@components/AlertWithRefetch/AlertWithRefetch';
import { DetailCell } from '@components/DataGridCells/DetailCell';
import { Spinner } from '@components/Spinner';
import TanStackTable from '@components/TanStackTable/TanStackTable';
import { useRedirect } from '@hooks';
import {
  ColumnDef,
  OnChangeFn,
  PaginationState,
  SortingState,
  Updater,
} from '@tanstack/react-table';
import { t } from '@utils/translate';

const createColumns = (
  redirect: ReturnType<typeof useRedirect>,
): ColumnDef<OrganizationSimpleDto>[] => [
  {
    header: t('headerName'),
    accessorKey: 'name',
    cell: ({ row }) => <div style={{ flex: 0.5 }}>{row.original.name}</div>,
  },
  {
    header: t('headerMainContact'),
    accessorKey: 'manager_name',
    cell: ({ row }) => <div style={{ flex: 0.5 }}>{row.original.manager_name}</div>,
  },
  {
    header: t('headerCity'),
    accessorKey: 'city',
    cell: ({ row }) => <div style={{ flex: 0.5 }}>{row.original.city}</div>,
  },
  {
    header: t('headerCountry'),
    accessorKey: 'country',
    cell: ({ row }) => (
      <div style={{ minWidth: 200 }}>
        <DetailCell
          onDetailClick={() =>
            redirect((paths) => paths.configurationOrganization(row.original.id))
          }
        >
          {row.original.country}
        </DetailCell>
      </div>
    ),
  },
];

export type OrganizationListProps = {
  filters: FilterParamsCommonDto;
  sorting: SortingState;
  pagination: PaginationState;
  onSortingChange: (sorting: Updater<SortingState>) => void;
  onPaginationChange: OnChangeFn<PaginationState>;
};

export const OrganizationList = ({
  filters,
  sorting,
  pagination,
  onSortingChange,
  onPaginationChange,
}: OrganizationListProps) => {
  const redirect = useRedirect();

  const columns = createColumns(redirect);

  const { data, isError, refetch, isLoading, isFetching } = useGetOrganizationsQuery(filters, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <>
      {(isLoading || isFetching) && <Spinner />}
      {isError && (
        <AlertWithRefetch onRetryClick={refetch}>{t('errorGetOrganizations')}</AlertWithRefetch>
      )}
      {data && !isLoading && !isFetching && (
        <TanStackTable<OrganizationSimpleDto>
          columns={columns}
          rows={data.organizations || []}
          itemsCount={data.count || 0}
          sorting={sorting}
          pagination={pagination}
          onSortingChange={onSortingChange}
          onPaginationChange={onPaginationChange}
        />
      )}
    </>
  );
};
