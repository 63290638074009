import { useState, useEffect, useRef, useMemo } from 'react';
import { Node } from '@appTypes/models/site.dto';
import { CenterX, CenterY, FlexColumn } from '@components/LayoutUtils';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { Wrapper, ArrowIcon } from '@pages/Sites/Overview/DataExport/DevicesTree/Components';

type CheckboxTreeProps = {
  data: Node[];
  selectedIds: string[];
  onChange: (updatedIds: string[]) => void;
  searchText?: string;
};

const CheckboxTree = ({ data, selectedIds, onChange, searchText = '' }: CheckboxTreeProps) => {
  const [expandedNodes, setExpandedNodes] = useState<Record<string, boolean>>({});
  const previousSearchText = useRef(searchText);

  const toggleExpand = (nodeId: string) => {
    setExpandedNodes((prev) => ({
      ...prev,
      [nodeId]: !prev[nodeId],
    }));
  };

  const handleBranchToggle = (branch: Node, checked: boolean) => {
    const updatedSelectedIds = new Set(selectedIds);

    branch.children.forEach((child) => {
      if (checked) updatedSelectedIds.add(child.id);
      else updatedSelectedIds.delete(child.id);
    });

    onChange(Array.from(updatedSelectedIds));
  };

  const handleLeafToggle = (leafId: string, checked: boolean) => {
    const updatedSelectedIds = new Set(selectedIds);

    if (checked) updatedSelectedIds.add(leafId);
    else updatedSelectedIds.delete(leafId);

    onChange(Array.from(updatedSelectedIds));
  };

  const isBranchChecked = (branch: Node) =>
    branch.children.every((child) => selectedIds.includes(child.id));

  const isBranchIndeterminate = (branch: Node) =>
    branch.children.some((child) => selectedIds.includes(child.id)) && !isBranchChecked(branch);

  useEffect(() => {
    const shouldExpandBranch = (branch: Node) => {
      if (searchText) {
        return branch.children.some((child) =>
          child.name.toLowerCase().includes(searchText.toLowerCase()),
        );
      }
      return branch.children.some((child) => selectedIds.includes(child.id));
    };

    if (previousSearchText.current !== searchText) {
      const newExpandedNodes = data.reduce(
        (acc, branch) => {
          acc[branch.id] = shouldExpandBranch(branch);
          return acc;
        },
        {} as Record<string, boolean>,
      );
      setExpandedNodes(newExpandedNodes);
      previousSearchText.current = searchText;
    }
  }, [searchText, selectedIds, data]);

  const filteredData = useMemo(
    () =>
      data
        .map((branch) => ({
          ...branch,
          children: branch.children.filter((child) =>
            child.name.toLowerCase().includes(searchText.toLowerCase()),
          ),
        }))
        .filter((branch) => branch.children.length > 0),
    [data, searchText],
  );

  return (
    <Wrapper>
      {filteredData.map((branch) => (
        <Box key={branch.id}>
          <CenterX>
            <CenterY mr={1} sx={{ cursor: 'pointer' }} onClick={() => toggleExpand(branch.id)}>
              <ArrowIcon open={!!expandedNodes[branch.id]} />
            </CenterY>

            <FormControlLabel
              control={
                <Checkbox
                  checked={isBranchChecked(branch)}
                  indeterminate={isBranchIndeterminate(branch)}
                  onChange={(e) => handleBranchToggle(branch, e.target.checked)}
                />
              }
              label={
                <Typography variant="body1" fontWeight="bold">
                  {branch.name}
                </Typography>
              }
            />
          </CenterX>

          {expandedNodes[branch.id] && (
            <FlexColumn pl={8}>
              {branch.children.map((leaf) => (
                <FormControlLabel
                  key={leaf.id}
                  control={
                    <Checkbox
                      checked={selectedIds.includes(leaf.id)}
                      onChange={(e) => handleLeafToggle(leaf.id, e.target.checked)}
                    />
                  }
                  label={leaf.name}
                />
              ))}
            </FlexColumn>
          )}
        </Box>
      ))}
    </Wrapper>
  );
};

export default CheckboxTree;
