import { useGetUsersFilteredQuery } from '@apiRtk/users';
import { FilterParamsCommonDto } from '@appTypes/models/common.dto';
import { UserSimpleDto } from '@appTypes/models/user.dto';
import AlertWithRefetch from '@components/AlertWithRefetch/AlertWithRefetch';
import { DetailCell } from '@components/DataGridCells/DetailCell';
import { Flex } from '@components/LayoutUtils';
import { Spinner } from '@components/Spinner';
import { Switch } from '@components/Switch';
import TanStackTable from '@components/TanStackTable/TanStackTable';
import { Tooltip } from '@components/Tooltip/Tooltip';
import { useRedirect } from '@hooks';
import {
  ColumnDef,
  OnChangeFn,
  PaginationState,
  SortingState,
  Updater,
} from '@tanstack/react-table';
import { createFullName } from '@utils/data/createFullName';
import { t } from '@utils/translate';
import { palette } from 'styles/palette';

const createColumns = (redirect: ReturnType<typeof useRedirect>): ColumnDef<UserSimpleDto>[] => [
  {
    header: t('headerStatus'),
    accessorKey: 'is_active',
    cell: ({ row }) => (
      <Flex
        style={{
          maxWidth: '80px',
          color: row.original.is_active ? undefined : palette.neutral.main,
        }}
      >
        <Tooltip
          trigger={<Switch size="small" checked={row.original.is_active} disabled />}
          placement="top"
        >
          {row.original.is_active ? t('buttonActivateUser') : t('buttonDeactivateUser')}
        </Tooltip>
      </Flex>
    ),
  },
  {
    header: t('headerName'),
    accessorKey: 'last_name',
    cell: ({ row }) => (
      <div
        style={{
          flex: 0.5,
          color: row.original.is_active ? undefined : palette.neutral.main,
        }}
      >
        {createFullName(row.original.first_name, row.original.last_name, true)}
      </div>
    ),
  },
  {
    header: t('headerEmail'),
    accessorKey: 'email',
    cell: ({ row }) => (
      <div
        style={{
          flex: 0.5,
          color: row.original.is_active ? undefined : palette.neutral.main,
        }}
      >
        {row.original.email}
      </div>
    ),
  },
  {
    id: 'organization.name',
    header: t('headerOrganization'),
    accessorKey: 'organization',
    cell: ({ row }) => (
      <div
        style={{
          minWidth: '200px',
        }}
      >
        <DetailCell
          color={row.original.is_active ? undefined : palette.error.main}
          onDetailClick={() => redirect((paths) => paths.configurationUser(row.original.id))}
        >
          {row.original.is_active ? row.original.organization?.name : t('statusUserDeactivated')}
        </DetailCell>
      </div>
    ),
  },
];

export type UserListProps = {
  filters: FilterParamsCommonDto;
  sorting: SortingState;
  pagination: PaginationState;
  onSortingChange: (sorting: Updater<SortingState>) => void;
  onPaginationChange: OnChangeFn<PaginationState>;
};

export const UserList = ({
  filters,
  sorting,
  pagination,
  onSortingChange,
  onPaginationChange,
}: UserListProps) => {
  const redirect = useRedirect();

  const columns = createColumns(redirect);

  const { data, isError, refetch, isLoading, isFetching } = useGetUsersFilteredQuery(filters, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <>
      {(isLoading || isFetching) && <Spinner />}
      {isError && <AlertWithRefetch onRetryClick={refetch}>{t('errorGetUsers')}</AlertWithRefetch>}
      {data && !isLoading && !isFetching && (
        <TanStackTable<UserSimpleDto>
          columns={columns}
          rows={data.users || []}
          itemsCount={data.count || 0}
          sorting={sorting}
          pagination={pagination}
          onSortingChange={onSortingChange}
          onPaginationChange={onPaginationChange}
        />
      )}
    </>
  );
};
