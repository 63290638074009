import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { styled } from '@mui/material/styles';
import { space } from '@utils/utils';

export const ArrowIcon = styled(KeyboardArrowRightIcon)<{ open?: boolean }>(({ theme, open }) => ({
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
}));

export const Wrapper = styled('div')(({ theme }) => ({
  marginTop: '10px',
  userSelect: 'none',
  height: '400px',
  overflowY: 'scroll',
  padding: space(2),
  boxSizing: 'content-box',
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: '5px',
}));
