import { ButtonBack } from '@components/Buttons/Buttons';
import { ConfiguredItem } from '@components/ConfigurationList/types';
import { TitleBar } from '@components/TitleBar/TitleBar';
import { t } from '@utils/translate';
import { paths } from 'paths';
import CreateOrganizationForm from './OrganizationForm/CreateOrganizationForm';

const CreateOrganization = () => (
  <>
    <ButtonBack path={paths.configuration} query={{ selectedItem: ConfiguredItem.ORGANIZATIONS }}>
      {t('buttonBackToConfigurationList')}
    </ButtonBack>
    <TitleBar my={4} title={t('labelOrganizationName')} titleVariant="h5" />
    <CreateOrganizationForm />
  </>
);

export default CreateOrganization;
