import logo from '@assets/images/logotype.png';
import { Flex } from '@components/LayoutUtils';
import { t } from '@utils/translate';
import { palette } from 'styles/palette';

export const Header = () => (
  <Flex cX cY data-testid="logo" height={80} p={2} bgcolor={palette.white}>
    <img src={logo} alt={t('altLogo')} />
  </Flex>
);
