import { EcdDto } from '@appTypes/models/ecd.dto';
import { t } from '@utils/translate';

export type PrepareEcdDetailsFormInitValData = EcdDto & {
  site_id?: Nullable<number>;
};

export const prepareEcdDetailsFormInitVal = (ecd: PrepareEcdDetailsFormInitValData) => {
  const { name, id, is_active, creation_date, last_modify_date, site, site_id: editedSiteId } = ecd;

  const site_id = (() => {
    if (editedSiteId !== undefined) {
      return editedSiteId;
    }

    return typeof site?.id === 'number' ? site.id : null;
  })();

  return {
    name,
    id,
    creation_date,
    last_modify_date,
    status: is_active ? t('labelActive') : t('labelInactive'),
    site_id,
  };
};
