import { useLazyRestartECDQuery } from '@apiRtk/imps';
import { ButtonSecondary } from '@components/Buttons/Buttons';
import { FormInputProps } from '@components/FormInput';
import { CenterX } from '@components/LayoutUtils';
import { TriggerWithConfirmModal } from '@components/Modals/ConfirmModal';
import { TitleBar } from '@components/TitleBar/TitleBar';
import { useCurrentUserInfo } from '@hooks/useCurrentUserInfo';
import MemoryIcon from '@mui/icons-material/Memory';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Typography } from '@mui/material';
import { t } from '@utils/translate';
import { Control, FieldValues, UseFormRegister } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { EcdFormInput } from '../EcdFormInput/EcdFormInput';
import { EcdFormWrapper } from '../EcdFormWrapper/EcdFormWrapper';

export type EcdDetailField = {
  initValue: FormInputProps['initValue'];
} & Pick<FormInputProps, 'name' | 'label' | 'readOnly' | 'isDate' | 'CustomComponent'>;

type EcdDetailsProps = {
  registerField: UseFormRegister<FieldValues>;
  fields: EcdDetailField[];
  control: Control<FieldValues>;
  viewOnlyMode: boolean;
};

export const EcdDetails = ({ registerField, fields, control, viewOnlyMode }: EcdDetailsProps) => {
  const { isUserOnly } = useCurrentUserInfo();
  const [restartEcd] = useLazyRestartECDQuery();
  const params = useParams();
  const ecdId = params.id!;

  return (
    <>
      <TitleBar my={4} title={t('headingEcdDetails')} titleVariant="h5" />
      <EcdFormWrapper wrapperProps={{ p: 0 }}>
        {fields.map(({ name, readOnly, ...rest }) => (
          <EcdFormInput
            {...rest}
            readOnly={viewOnlyMode || readOnly}
            control={control}
            key={name}
            {...registerField(name)}
            data-testid={`ecd-details-${name}`}
          />
        ))}
        <div />

        {!isUserOnly && (
          <TriggerWithConfirmModal
            renderTrigger={(openModal) => (
              <ButtonSecondary
                startIcon={<RestartAltIcon />}
                sx={{ width: '100px', marginLeft: 'auto' }}
                onClick={openModal}
              >
                {t('buttonRestartEcd')}
              </ButtonSecondary>
            )}
            onConfirm={() => restartEcd(ecdId)}
            dialogTitle={t('buttonRestartEcd')}
          >
            <Typography variant="subtitle1">{t('dialogTextRestartEcd1')}:</Typography>
            <CenterX p={2} pl={0} gap={0.5}>
              <MemoryIcon color="secondary" />
              <Typography variant="caption" fontWeight="bold">
                {ecdId}
              </Typography>
            </CenterX>
            <Typography variant="subtitle1">{t('dialogTextRestartEcd2')}</Typography>
            <Typography variant="subtitle1">{t('dialogTextRestartEcd3')}</Typography>
          </TriggerWithConfirmModal>
        )}
      </EcdFormWrapper>
    </>
  );
};
