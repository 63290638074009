import { regex } from '@settings/regex';
import { t } from '@utils/translate';
import { z } from 'zod';

export const siteFormSchema = z.object({
  organization_id: z.number({
    required_error: t('validationOrganizationIdRequired'),
    invalid_type_error: t('validationOrganizationIdInvalidType'),
  }),
  name: z
    .string({
      required_error: t('validationNameRequired'),
      invalid_type_error: t('validationNameInvalidType'),
    })
    .regex(regex.siteName, {
      message: t('validationNameInvalidCharacters'),
    })
    .min(2, { message: t('validationNameMinLength') })
    .max(50, { message: t('validationNameMaxLength') }),
  street: z
    .string({
      required_error: t('validationStreetRequired'),
      invalid_type_error: t('validationStreetInvalidType'),
    })
    .regex(regex.street, { message: t('validationStreetInvalidCharacters') })
    .min(2, { message: t('validationStreetMinLength') })
    .max(50, { message: t('validationStreetMaxLength') }),
  city: z
    .string({
      required_error: t('validationCityRequired'),
      invalid_type_error: t('validationCityInvalidType'),
    })
    .regex(regex.city, {
      message: t('validationCityInvalidCharacters'),
    })
    .min(2, { message: t('validationCityMinLength') })
    .max(50, { message: t('validationCityMaxLength') }),
  postal_code: z
    .string({
      required_error: t('validationPostalCodeRequired'),
      invalid_type_error: t('validationPostalCodeInvalidType'),
    })
    .regex(regex.postalCode, { message: t('validationPostalCodeInvalidCharacters') })
    .min(4, { message: t('validationPostalCodeMinLength') })
    .max(10, { message: t('validationPostalCodeMaxLength') }),
  country: z
    .string({
      required_error: t('validationCountryRequired'),
      invalid_type_error: t('validationCountryInvalidType'),
    })
    .regex(regex.country, {
      message: t('validationCountryInvalidCharacters'),
    })
    .min(4, { message: t('validationCountryMinLength') })
    .max(30, { message: t('validationCountryMaxLength') }),
  lat: z
    .string()
    .refine((value) => value !== '', {
      message: t('validationLatitudeRequired'),
    })
    .refine((value) => !Number.isNaN(value), {
      message: t('validationLatitudeMustBeNumber'),
    })
    .refine((value) => Number(value) >= -90 && Number(value) <= 90, {
      message: t('validationLatitudeRange'),
    }),
  lon: z
    .string()
    .refine((value) => value !== '', {
      message: t('validationLongitudeRequired'),
    })
    .refine((value) => !Number.isNaN(value), {
      message: t('validationLongitudeMustBeNumber'),
    })
    .refine((value) => Number(value) >= -180 && Number(value) <= 180, {
      message: t('validationLongitudeRange'),
    }),
});
