import SiteHeader, { TabItemId } from '@components/SiteHeader/SiteHeader';
import { useCurrentUserInfo } from '@hooks/useCurrentUserInfo';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import { Box } from '@mui/material';
import { t } from '@utils/translate';

interface SiteHeaderWrapperProps {
  siteName: string;
  siteId: number;
  children: React.ReactNode;
}

export const SiteHeaderWrapper = ({ siteName, siteId, children }: SiteHeaderWrapperProps) => {
  const { isUserOnly } = useCurrentUserInfo();

  const getTabItems = (id: number) => {
    if (!id) {
      return [];
    }

    const tabs = [
      {
        id: TabItemId.OVERVIEW,
        label: t('tabOverview'),
        path: `/sites/${id}/overview`,
      },
      {
        id: TabItemId.DATA_ANALYSIS,
        label: t('tabDataAnalysis'),
        path: `/sites/${id}/data-analysis`,
      },
    ];

    if (!isUserOnly) {
      tabs.push(
        {
          id: TabItemId.ALARMS,
          label: t('tabAlarms'),
          path: `/sites/${id}/alarms`,
        },
        {
          id: TabItemId.NOTIFICATIONS,
          label: t('tabNotifications'),
          path: `/sites/${id}/notifications`,
        },
      );
    }

    tabs.push({
      id: TabItemId.CONFIGURATION,
      label: t('tabConfiguration'),
      path: `/sites/${id}/configuration`,
    });

    return tabs;
  };

  return (
    <div>
      <SiteHeader
        siteName={siteName}
        headerIcon={BusinessRoundedIcon}
        tabItems={getTabItems(siteId)}
      />
      <Box px={6} py={4} flexGrow={1} overflow="hidden">
        {children}
      </Box>
    </div>
  );
};
