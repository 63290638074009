import ConfirmModal from '@components/Modals/ConfirmModal';
import { usePalette } from '@hooks';
import { Typography } from '@mui/material';
import { t } from '@utils/translate';

interface DataExportSuccessMessageProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DataExportSuccessMessage = ({ open, setOpen }: DataExportSuccessMessageProps) => {
  const currentPalette = usePalette();

  return (
    <ConfirmModal
      open={open}
      setOpen={setOpen}
      confirmButtonCaption={t('buttonClose')}
      dialogTitle={t('dialogTitleDataExportRequest')}
      noCancelButton
    >
      <Typography fontSize={16} color={currentPalette.text.secondary}>
        {t('infoDataExportRequestReceived')}
      </Typography>
    </ConfirmModal>
  );
};

export default DataExportSuccessMessage;
