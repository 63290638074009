import { useEffect, useState } from 'react';
import { useExportMutation, useGetExportDevicesConfigQuery } from '@apiRtk/sites';
import { FormValuesDataExport } from '@appTypes/forms';
import { ExportDataFormat, ExportGranularity, ExportMultiplier } from '@appTypes/models/site.dto';
import { ButtonCancel, ButtonPrimary } from '@components/Buttons/Buttons';
import { DateTime } from '@components/Form/DateTime';
import { CenterX } from '@components/LayoutUtils';
import { Spinner } from '@components/Spinner';
import { Tooltip } from '@components/Tooltip/Tooltip';
import { usePalette } from '@hooks';
import { ReactComponent as IconInfo } from '@icons/info.svg';
import { Close, Download } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';

import {
  Alert,
  Box,
  Drawer,
  IconButton,
  MenuItem,
  Select,
  Typography,
  styled,
} from '@mui/material';
import { t } from '@utils/translate';
import { addHours, differenceInSeconds, subHours } from 'date-fns';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { DevicesTreeAdvanced } from './DevicesTree/DeviceTree';

const multipliers: { label: string; value: ExportMultiplier }[] = [
  { label: 'base', value: 'b' },
  { label: 'kilo', value: 'k' },
  { label: 'Mega', value: 'M' },
  { label: 'Giga', value: 'G' },
];

const granularityItems: { label: string; value: ExportGranularity }[] = [
  { label: t('labelGranularity10Minutes'), value: '10m' },
  { label: t('labelGranularity15Minutes'), value: '15m' },
  { label: t('labelGranularity30Minutes'), value: '30m' },
  { label: t('labelGranularity1Hour'), value: '1h' },
  { label: t('labelGranularity24Hours'), value: '24h' },
];

const dataFormatItems: { label: string; value: ExportDataFormat }[] = [
  { label: 'XLSX', value: 'xlsx' },
  { label: 'CSV', value: 'csv' },
];

const DrawerHeader = ({
  children,
  onCloseClick,
}: React.PropsWithChildren<{ onCloseClick?: () => void }>) => (
  <CenterX justifyContent="space-between">
    <Typography variant="h5">{children}</Typography>
    {onCloseClick ? (
      <IconButton color="primary" onClick={onCloseClick}>
        <Close />
      </IconButton>
    ) : null}
  </CenterX>
);

const Container = styled(Box)`
  width: 500px;
  box-sizing: border-box;
  display: grid;
`;

type DataExportPanelProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccessMessageOpen: (open: boolean) => void;
};

const Section = ({
  children,
  title,
  tooltipContent,
}: React.PropsWithChildren<{ title: string; tooltipContent?: string }>) => (
  <section>
    <CenterX gap={1} pb={1}>
      <Typography variant="h6">{title}</Typography>
      {tooltipContent ? (
        <Tooltip trigger={<IconInfo width={16} />} placement="top">
          <Typography variant="body2" fontWeight="bold" color="white">
            {tooltipContent}
          </Typography>
        </Tooltip>
      ) : null}
    </CenterX>
    {children}
  </section>
);

const defaultValues: FormValuesDataExport = {
  start: null,
  end: null,
  series: [],
  unit: null,
  multiplier: null,
  granularity: null,
  format: 'xlsx',
  metrics: [],
};

const DataExportPanelAdvanced = ({ open, setOpen, onSuccessMessageOpen }: DataExportPanelProps) => {
  const [runExport, { isLoading, isSuccess, isError }] = useExportMutation();
  const [formError, setFormError] = useState('');
  const currentPalette = usePalette();

  const params = useParams();
  const siteId = parseInt(params.id as string, 10);
  const { isLoading: exportDevicesConfigLoading, data: devicesConfigAdvanced } =
    useGetExportDevicesConfigQuery({ siteId }, { skip: !siteId || !open });

  const devicesConfig =
    devicesConfigAdvanced && devicesConfigAdvanced?.length > 10
      ? devicesConfigAdvanced?.slice(10)
      : devicesConfigAdvanced;

  const { handleSubmit, control, formState, reset, watch, setValue } =
    useForm<FormValuesDataExport>({
      defaultValues,
    });

  useEffect(() => {
    if (isSuccess && formState.isSubmitSuccessful) {
      onSuccessMessageOpen(true);
      setOpen(false);
      reset();
    }
  }, [formState.isSubmitSuccessful, isSuccess, reset, onSuccessMessageOpen, setOpen]);

  const onSubmit: SubmitHandler<FormValuesDataExport> = (data) => {
    const { start, end, granularity, unit, format, series } = data;

    if (start && end && granularity && unit && format) {
      if (differenceInSeconds(end, start) <= 3600) {
        setFormError(t('errorTimePeriodTooShort'));
        return;
      }
      setFormError('');

      runExport({
        siteId,
        start,
        end,
        granularity,
        unit,
        series,
        format,
      });
    }
  };

  const getMinDateTime = () => {
    const start = watch('start');
    if (!start) {
      return undefined;
    }

    const startDate = new Date(start);
    if (Number.isNaN(startDate)) {
      return undefined;
    }

    return addHours(startDate, 1);
  };

  const getMaxDateTime = () => {
    const end = watch('end');
    if (!end) {
      return undefined;
    }

    const endDate = new Date(end);
    if (Number.isNaN(endDate)) {
      return undefined;
    }

    return subHours(endDate, 1);
  };

  return (
    <Drawer open={open} anchor="right">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container p={3} gap={4}>
          <DrawerHeader onCloseClick={() => setOpen(false)}>
            <CenterX gap={1}>
              <DownloadIcon />
              {t('titleDataDownload')}
            </CenterX>
          </DrawerHeader>
          <Typography variant="body2">{t('labelExportInfo')}</Typography>

          <Section title={t('titleTimePeriod')} tooltipContent={t('tooltipDataExportInUtc')}>
            <CenterX gap={2}>
              <Controller
                rules={{ required: true }}
                control={control}
                name="start"
                render={({ field: { onChange, value } }) => (
                  <DateTime
                    label={t('labelTimeFrom')}
                    val={value}
                    onChange={onChange}
                    maxDateTime={getMaxDateTime()}
                    size="small"
                  />
                )}
              />
              <Controller
                rules={{ required: true }}
                control={control}
                name="end"
                render={({ field: { onChange, value } }) => (
                  <DateTime
                    label={t('labelTimeTo')}
                    val={value}
                    onChange={onChange}
                    minDateTime={getMinDateTime()}
                    size="small"
                  />
                )}
              />
            </CenterX>
          </Section>

          <Section title={t('titleDataType')}>
            {!exportDevicesConfigLoading && devicesConfig && devicesConfigAdvanced ? (
              <DevicesTreeAdvanced
                setValue={setValue}
                watch={watch}
                devices={devicesConfig}
                devicesAdvanced={devicesConfigAdvanced}
              />
            ) : (
              <Spinner />
            )}
          </Section>

          <Section title={t('titleValueMultiplier')}>
            <CenterX gap={2}>
              <Controller
                name="multiplier"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    size="small"
                    label={t('labelSelectMultiplier')}
                    value={value || ''}
                    onChange={onChange}
                    fullWidth
                  >
                    {multipliers.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <Controller
                name="granularity"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    size="small"
                    label={t('labelSelectGranularity')}
                    value={value || ''}
                    onChange={onChange}
                    fullWidth
                  >
                    {granularityItems.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </CenterX>
          </Section>

          <Section title={t('titleDataFormat')}>
            <CenterX>
              <Controller
                name="format"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    size="small"
                    label={t('labelSelectFormat')}
                    sx={{ flex: 0.5, mr: 2 }}
                    value={value || ''}
                    onChange={onChange}
                    fullWidth
                  >
                    {dataFormatItems.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </CenterX>
          </Section>

          {isError && <Alert severity="error">{t('errorCommonAction')}</Alert>}

          {formError && <Alert severity="error">{formError}</Alert>}
          <CenterX gap={1}>
            <ButtonPrimary
              startIcon={
                isLoading ? (
                  <Spinner
                    color={currentPalette.getContrastText(currentPalette.secondary.main)}
                    size={22}
                  />
                ) : (
                  <Download />
                )
              }
              type="submit"
              disabled // TODO: enable, once backend is ready
            >
              {t('buttonDownloadData')}
            </ButtonPrimary>

            <ButtonCancel onClick={() => setOpen(false)}>{t('buttonCancel')}</ButtonCancel>
          </CenterX>
        </Container>
      </form>
    </Drawer>
  );
};

export default DataExportPanelAdvanced;
