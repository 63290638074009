import { CustomSelectBoxProps, SelectBox } from '@components/SelectBox/SelectBox';
import { Switch } from '@components/Switch';
import { t } from '@utils/translate';

export enum IsActiveSelectBoxVal {
  ACTIVE = '1',
  INACTIVE = '2',
}

const activeSelectBoxOpts = [
  {
    id: IsActiveSelectBoxVal.ACTIVE,
    name: (
      <>
        <Switch size="small" checked />
        {t('labelActive')}
      </>
    ),
  },
  {
    id: IsActiveSelectBoxVal.INACTIVE,
    name: (
      <>
        <Switch size="small" checked={false} />
        {t('labelInactive')}
      </>
    ),
  },
];

export const IsActiveSelectBox = (
  props: Omit<CustomSelectBoxProps, 'options' | 'disableEmptyValue'>,
) => <SelectBox {...props} options={activeSelectBoxOpts} disableEmptyValue />;
