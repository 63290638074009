import { UserSimpleDto } from '@appTypes/models/user.dto';
import { Box, Typography } from '@mui/material';
import { t } from '@utils/translate';

interface UserLineProps {
  user: Nullable<UserSimpleDto>;
}

const UserLine = ({ user }: UserLineProps) => {
  if (!user) {
    return <Typography variant="body1">{t('labelNoUserSelected')}</Typography>;
  }

  const { first_name, last_name, email } = user;

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography variant="body1">
        {last_name} {first_name} ({email})
      </Typography>
    </Box>
  );
};

export default UserLine;
