import React, { useState } from 'react';
import { ButtonCancel, ButtonPrimary } from '@components/Buttons/Buttons';
import { CenterX } from '@components/LayoutUtils';
import { Popover, Box } from '@mui/material';
import { t } from '@utils/translate';
import { enUS } from 'date-fns/locale';
import { DateRangePicker, RangeKeyDict } from 'react-date-range';
import { palette } from 'styles/palette';
import { staticRanges } from './rangePickerPresets';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

interface DateRangePickerPopoverProps {
  selectedRange: { startDate: Date; endDate: Date };
  onApply: (startDate: Date, endDate: Date) => void;
  triggerComponent: React.ReactNode;
}

const DateRangePickerPopover = ({
  selectedRange,
  onApply,
  triggerComponent,
}: DateRangePickerPopoverProps) => {
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLElement>>(null);
  const [tempDateRange, setTempDateRange] = useState({
    startDate: selectedRange.startDate,
    endDate: selectedRange.endDate,
  });

  const handleDateRangeChange = (ranges: RangeKeyDict) => {
    const { startDate, endDate } = ranges.selection;
    if (startDate && endDate) {
      setTempDateRange({ startDate, endDate });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApply = () => {
    const now = new Date();

    // Set startDate to 00:00
    const adjustedStartDate = new Date(tempDateRange.startDate);
    adjustedStartDate.setHours(0, 0, 0, 0);

    // Set endDate to 23:59 but cap it to the current date and time if necessary
    const adjustedEndDate = new Date(tempDateRange.endDate);
    adjustedEndDate.setHours(23, 59, 59, 999);

    const finalEndDate = adjustedEndDate > now ? now : adjustedEndDate;

    onApply(adjustedStartDate, finalEndDate);
    handleClose();
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <div onClick={handleOpen}>{triggerComponent}</div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        marginThreshold={10}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box>
          <DateRangePicker
            maxDate={new Date()}
            locale={enUS}
            months={2}
            onChange={handleDateRangeChange}
            rangeColors={[palette.primary.main]}
            inputRanges={[]}
            ranges={[
              {
                startDate: tempDateRange.startDate,
                endDate: tempDateRange.endDate,
                key: 'selection',
              },
            ]}
            staticRanges={staticRanges}
          />
          <CenterX gap={1} p={1} justifyContent="center" sx={{ background: palette.neutral.light }}>
            <ButtonPrimary size="small" onClick={handleApply}>
              {t('buttonApply')}
            </ButtonPrimary>
            <ButtonCancel size="small" onClick={handleClose}>
              {t('buttonCancel')}
            </ButtonCancel>
          </CenterX>
        </Box>
      </Popover>
    </>
  );
};

export default DateRangePickerPopover;
