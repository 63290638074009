import { useGetNotificationsQuery } from '@apiRtk/notifications';
import { FilterParamsCommonDto } from '@appTypes/models/common.dto';
import { NotificationDto } from '@appTypes/models/notification.dto';
import AlertWithRefetch from '@components/AlertWithRefetch/AlertWithRefetch';
import { CenterX } from '@components/LayoutUtils';
import { Spinner } from '@components/Spinner';
import DateTimeRange from '@components/TanStackTable/DateTimeRange';
import TanStackTable from '@components/TanStackTable/TanStackTable';
import {
  ColumnDef,
  OnChangeFn,
  PaginationState,
  SortingState,
  Updater,
} from '@tanstack/react-table';
import { t } from '@utils/translate';

const createColumns = (): ColumnDef<NotificationDto>[] => [
  {
    header: t('headerSite'),
    accessorKey: 'site',
    enableSorting: false,
    cell: ({ row }) => (
      <div style={{ flex: 1, minWidth: 130 }}>
        <CenterX gap={1}>
          <span>{row.original.site.name}</span>
        </CenterX>
      </div>
    ),
  },
  {
    header: t('headerDevice'),
    accessorKey: 'device',
    enableSorting: false,
    cell: ({ row }) => <div style={{ flex: 1.2, minWidth: 130 }}>{row.original.device}</div>,
  },
  {
    header: t('headerNotification'),
    accessorKey: 'message',
    enableSorting: false,
    cell: ({ row }) => <div style={{ flex: 1.5, minWidth: 130 }}>{row.original.message}</div>,
  },
  {
    header: t('headerDateTime'),
    accessorKey: 'date',
    enableSorting: false,
    cell: ({ row }) => (
      <div style={{ maxWidth: 300, minWidth: 300 }}>
        <DateTimeRange startString={row.original.start} endString={row.original.end} />
      </div>
    ),
  },
];

export type NotificationListProps = {
  filters: FilterParamsCommonDto;
  sorting: SortingState;
  pagination: PaginationState;
  onSortingChange: (sorting: Updater<SortingState>) => void;
  onPaginationChange: OnChangeFn<PaginationState>;
};

export const NotificationList = ({
  filters,
  sorting,
  pagination,
  onSortingChange,
  onPaginationChange,
}: NotificationListProps) => {
  const columns = createColumns();

  const { data, isError, refetch, isLoading, isFetching } = useGetNotificationsQuery(filters, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <>
      {(isLoading || isFetching) && <Spinner />}
      {isError && <AlertWithRefetch onRetryClick={refetch}>{t('errorNoData')}</AlertWithRefetch>}
      {data && !isLoading && !isFetching && (
        <TanStackTable<NotificationDto>
          columns={columns}
          rows={data.notifications || []}
          itemsCount={data.count || 0}
          sorting={sorting}
          pagination={pagination}
          onSortingChange={onSortingChange}
          onPaginationChange={onPaginationChange}
        />
      )}
    </>
  );
};
