import { ReactNode } from 'react';
import { divideWithSI } from '@components/Charts/helpers';
import { CenterX, GapX } from '@components/LayoutUtils';
import { usePalette } from '@hooks';
import { GridProps } from '@mui/material';
import { formatCurrency, space } from '@utils/utils';
import { TextLarge, Dot, TextNormal } from './components';

type CardListItemProps = GridProps & {
  value: number;
  valueColor?: string;
  color?: string;
  suffix?: string;
  prefix?: string;
  subItem?: boolean;
  customMarker?: ReactNode;
  light?: boolean;
  boldValue?: boolean;
  currency?: boolean;
  icon?: ReactNode;
};

const CardListItem = ({
  color,
  value,
  prefix,
  suffix,
  subItem,
  valueColor,
  customMarker,
  light,
  currency = false,
  boldValue = false,
  icon,
  ...props
}: CardListItemProps) => {
  const decimals = 1;
  const { resultValue, prefix: prefixSI } = divideWithSI(value, decimals);
  let parsedValue = resultValue.toFixed(decimals);
  const currentPalette = usePalette();

  if (currency) {
    parsedValue = formatCurrency(value);
  }

  const getValueColor = (itemValue: number) => {
    if (itemValue < 0) {
      return currentPalette.error.main;
    }
    return currentPalette.primary.main;
  };

  const lightContent = (
    <>
      <TextNormal color={currentPalette.text.secondary} smaller>
        {prefix}
      </TextNormal>
      <TextNormal
        color={valueColor || getValueColor(value)}
        sx={{ fontWeight: boldValue ? 'bold' : 'normal' }}
      >
        {parsedValue}
      </TextNormal>
      <TextNormal color={currentPalette.text.secondary} smaller>
        {!currency ? prefixSI : null}
        {suffix}
      </TextNormal>
      {icon || null}
    </>
  );

  const content = (
    <>
      <TextNormal color={currentPalette.text.secondary} smaller={subItem}>
        {prefix}
      </TextNormal>
      <TextLarge color={valueColor || getValueColor(value)} smaller={subItem}>
        {parsedValue}
      </TextLarge>
      <TextNormal color={currentPalette.text.secondary} smaller={subItem}>
        {!currency ? prefixSI : null}
        {suffix}
      </TextNormal>
    </>
  );

  return (
    <CenterX gap={space(0.5)} {...props}>
      {subItem ? <GapX /> : null}
      {customMarker || null}
      {color && !customMarker ? <Dot color={color} /> : null}
      {light ? lightContent : content}
    </CenterX>
  );
};

export default CardListItem;
