import { StateFilter } from '@appTypes/helpers/filters';

export type FilterParamsCommonDto = {
  page_size?: number;
  page?: number;
  search_string?: string;
  order_by?: string[];
  descending?: boolean;
};

export type FilterParamsDto = FilterParamsCommonDto & {
  site_id?: number;
  is_active?: boolean;
};

export type FilterParamsTanstackDto = FilterParamsCommonDto & {
  site_id?: number;
  state?: StateFilter;
};

export interface GetAllDto {
  count: number;
}

export interface PerformanceOverTimeParamsDto {
  startDate: Date;
  endDate: Date;
  count: number;
  siteId: number;
  solarBaseline: boolean;
}

export enum ENDPOINT_WITH_FILTERS {
  ALARMS = 'alarms',
  IMPS = 'imps',
  NOTIFICATIONS = 'notifications',
  ORGANIZATIONS = 'organizations',
  RULES = 'rules',
  SITES = 'sites',
  USERS = 'users',
}
