import { FEATURE_FLAGS } from '@appTypes/common';
import { ListItemWithDatapoints, PerformanceOverTimeDto } from '@appTypes/models/cards.dto';
import CardHead from '@components/Cards/CardHead';
import CardSummaryItem from '@components/Cards/CardSummaryItem';
import { CardContentWithLoader } from '@components/Cards/CardWithLoader';
import { CardContainerColumn } from '@components/Cards/components';
import { FlexColumn, GapY } from '@components/LayoutUtils';
import Overlay from '@components/Overlay/Overlay';
import { ReactComponent as IconSolar } from '@icons/solar.svg';
import { getPerformanceOverTimeStaticMock } from '@mocks/performanceOverTime';
import { Box, Grid } from '@mui/material';
import { isFeatureFlagEnabled } from '@services/featureFlags';
import {
  createMinMaxMarkers,
  generateCardGraphColors,
  optionsAreaChart,
} from '@settings/apexOptions';
import { t } from '@utils/translate';
import AreaGraph from '../AreaGraph';
import { CardTooltip } from './CardTooltip';
import ComingSoonBig from './ComingSoonOverlay';

const CarbonGraph = ({ listItem }: { listItem: ListItemWithDatapoints }) => (
  <AreaGraph
    series={[
      {
        name: listItem.key,
        data: listItem.dataPoints,
      },
    ]}
    options={{
      ...optionsAreaChart,
      colors: generateCardGraphColors('sustGreen'),
      xaxis: {
        type: 'numeric',
      },
      annotations: {
        points: [...createMinMaxMarkers(listItem?.dataPoints || [])],
      },
    }}
  />
);

interface CardRenewablesProps {
  data?: PerformanceOverTimeDto['renewables'];
  loading?: boolean;
}

const CardRenewables = ({ data, loading }: CardRenewablesProps) => {
  const mockRenewables = getPerformanceOverTimeStaticMock(1).renewables; // placeholder

  const graphCarbonDataMock = mockRenewables?.items?.filter((item) => item.key === 'co2-avoided');

  return (
    <CardContainerColumn>
      <CardHead
        title={t('headingRenewableEnergy')}
        subtitle={t('labelCarbonEmissionsAvoided')}
        icon={<IconSolar />}
        rightSlot={<CardTooltip textKey="avoidedEmmisions" />}
      />
      <CardContentWithLoader loading={loading} sx={{ marginBottom: -2, marginX: -2 }}>
        {data ? (
          <>
            <Grid container px={2}>
              <Grid item xs={6}>
                <CardSummaryItem
                  value={data.generation}
                  title={t('labelRenewablesGenerated')}
                  unit="Wh"
                />
              </Grid>
              <Grid item xs={6}>
                <CardSummaryItem
                  value={data.gridExport}
                  title={t('labelExportedToTheGrid')}
                  unit="Wh"
                />
              </Grid>
            </Grid>
            <GapY size={3} />
            <FlexColumn position="relative">
              <Grid container>
                {!isFeatureFlagEnabled(FEATURE_FLAGS.DISABLE_COMING_SOON_OVERLAYS) ? (
                  <Overlay>
                    <Box>
                      <ComingSoonBig featureName={t('labelCarbonEmissionsAvoided')} />
                    </Box>
                  </Overlay>
                ) : null}
                <Grid item xs={6} px={2}>
                  <CardSummaryItem
                    value={mockRenewables.carbonAvoided}
                    title={
                      <>
                        {t('labelOf')} CO<sub>2</sub> {t('labelAvoided')}
                      </>
                    }
                    valueColor="green60"
                    unit="g"
                  />
                </Grid>
              </Grid>
              <GapY size={3} />
              {graphCarbonDataMock.length && graphCarbonDataMock[0]?.dataPoints?.length ? (
                <CarbonGraph listItem={graphCarbonDataMock[0]} />
              ) : null}
            </FlexColumn>
          </>
        ) : (
          <Box sx={{ height: '100px' }} />
        )}
      </CardContentWithLoader>
    </CardContainerColumn>
  );
};

export default CardRenewables;
