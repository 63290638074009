import { useState } from 'react';
import ButtonTabs, { ButtonTabsItem } from '@components/ButtonTabs/ButtonTabs';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { t } from '@utils/translate';
import {
  startOfDay,
  endOfDay,
  sub,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  subWeeks,
  endOfWeek,
} from 'date-fns';
import DateRangePicker from './DateRangePicker';

const getStartOfLastWeek = () => {
  const startOfLastWeek = startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 });
  return startOfDay(startOfLastWeek);
};

const getEndOfLastWeek = () => {
  const endOfLastWeek = endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 });
  return endOfDay(endOfLastWeek);
};

export const periods = [
  {
    id: 'yesterday',
    start: startOfDay(sub(new Date(), { days: 1 })),
    end: endOfDay(sub(new Date(), { days: 1 })),
    label: t('rangePresetYesterday'),
  },
  {
    id: 'week',
    start: getStartOfLastWeek(),
    end: getEndOfLastWeek(),
    label: t('rangePresetLastWeek'),
  },
  {
    id: 'month',
    start: startOfMonth(sub(new Date(), { months: 1 })),
    end: endOfMonth(sub(new Date(), { months: 1 })),
    label: t('rangePresetLastMonth'),
  },
];

type Props = {
  onChange: (start: Date, end: Date) => void;
};

const PeriodPicker = ({ onChange }: Props) => {
  const [calendarOpen, setCalendarOpen] = useState(false);

  return (
    <>
      <ButtonTabs initialSelectedIndex={0}>
        {periods.map((period) => (
          <ButtonTabsItem
            key={period.id}
            onClick={() => {
              onChange(period.start, period.end);
            }}
          >
            {period.label}
          </ButtonTabsItem>
        ))}
        <ButtonTabsItem
          onClick={() => {
            setCalendarOpen(true);
          }}
          startIcon={<DateRangeIcon sx={{ mt: '-3px' }} />}
        >
          {t('rangePresetCustom')}
        </ButtonTabsItem>
      </ButtonTabs>
      {calendarOpen && <DateRangePicker onChange={onChange} setCalendarOpen={setCalendarOpen} />}
    </>
  );
};
export default PeriodPicker;
