import { ActionCreator } from '@appTypes/helpers/redux';
import {
  EcdsActionsTypes,
  FetchedEcds,
  UpdateEcdsFilter,
  RequestEcdsFailed,
  RequestEcds,
  RequestEcdsSuccess,
  SetEcdRequestError,
  SetEcdRequestLoading,
  ClearEcdCurrent,
  FetchedGetEcd,
  GetEcdSuccess,
  UpdateEcdSuccess,
  FetchedUpdateEcd,
  FetchedEcdsCount,
  FetchedEcdsStatuses,
  RequestEcdStatus,
  RequestEcdStatusSuccess,
  RequestEcdStatusFailed,
} from './actionTypes';

export const requestEcds: ActionCreator<RequestEcds> = () => ({
  type: EcdsActionsTypes.REQUEST_ECDS,
});

export const fetchedGetEcd: ActionCreator<FetchedGetEcd> = (payload) => ({
  type: EcdsActionsTypes.FETCHED_ECD,
  payload,
});

export const getEcdSuccess: ActionCreator<GetEcdSuccess> = (payload) => ({
  type: EcdsActionsTypes.GET_ECD_SUCCESS,
  payload,
});

export const requestEcdsSuccess: ActionCreator<RequestEcdsSuccess> = (payload) => ({
  type: EcdsActionsTypes.REQUEST_ECDS_SUCCESS,
  payload,
});

export const requestEcdsFailed: ActionCreator<RequestEcdsFailed> = (payload) => ({
  type: EcdsActionsTypes.REQUEST_ECDS_FAILED,
  payload,
});

export const clearEcdCurrent: ActionCreator<ClearEcdCurrent> = () => ({
  type: EcdsActionsTypes.CLEAR_ECD_CURRENT,
});

export const setEcdRequestError: ActionCreator<SetEcdRequestError> = (payload) => ({
  type: EcdsActionsTypes.SET_ECD_REQUEST_ERROR,
  payload,
});

export const setEcdRequestLoading: ActionCreator<SetEcdRequestLoading> = (payload) => ({
  type: EcdsActionsTypes.SET_ECD_REQUEST_LOADING,
  payload,
});

export const updateEcdSuccess: ActionCreator<UpdateEcdSuccess> = (payload) => ({
  type: EcdsActionsTypes.UPDATE_ECD_SUCCESS,
  payload,
});

export const fetchedUpdateEcd: ActionCreator<FetchedUpdateEcd> = (payload) => ({
  type: EcdsActionsTypes.FETCHED_UPDATE_ECD,
  payload,
});

export const requestEcdStatus: ActionCreator<RequestEcdStatus> = (payload) => ({
  type: EcdsActionsTypes.REQUEST_ECD_STATUS,
  payload,
});

export const requestEcdStatusSuccess: ActionCreator<RequestEcdStatusSuccess> = (payload) => ({
  type: EcdsActionsTypes.REQUEST_ECD_STATUS_SUCCESS,
  payload,
});

export const requestEcdStatusFailed: ActionCreator<RequestEcdStatusFailed> = (payload) => ({
  type: EcdsActionsTypes.REQUEST_ECD_STATUS_FAILED,
  payload,
});

export type EcdsAction =
  | RequestEcds
  | RequestEcdsSuccess
  | RequestEcdsFailed
  | FetchedEcds
  | UpdateEcdsFilter
  | SetEcdRequestError
  | SetEcdRequestLoading
  | ClearEcdCurrent
  | FetchedGetEcd
  | GetEcdSuccess
  | UpdateEcdSuccess
  | FetchedEcdsCount
  | FetchedUpdateEcd
  | RequestEcdStatus
  | RequestEcdStatusSuccess
  | RequestEcdStatusFailed
  | FetchedEcdsStatuses;
