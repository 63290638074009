import { SiteSimpleDto } from '@appTypes/models/site.dto';
import { ButtonAdd } from '@components/Buttons/Buttons';
import { CenterX } from '@components/LayoutUtils';
import { SearchInput } from '@components/SearchInput';
import TanStackTable from '@components/TanStackTable/TanStackTable';
import { useRedirect, useFilters, useCurrentUserInfo } from '@hooks';
import { useDataManipulation } from '@hooks/useDataManipulation';
import { Button } from '@mui/material';
import CONFIG from '@settings/config';
import { ColumnDef } from '@tanstack/react-table';
import { t } from '@utils/translate';

type SitesGridCellProps = {
  id: number;
  name: string;
};

const CellWithRedirectButton = ({ id, name }: SitesGridCellProps) => {
  const redirect = useRedirect();

  return (
    <CenterX
      sx={{
        justifyContent: 'space-between',
        minWidth: '220px',
      }}
    >
      <span>{name}</span>
      <Button variant="outlined" onClick={() => redirect((paths) => paths.configurationSite(id))}>
        {t('buttonSiteDetail')}
      </Button>
    </CenterX>
  );
};

const columns: ColumnDef<SiteSimpleDto>[] = [
  {
    header: 'Name',
    accessorKey: 'name',
    enableSorting: false,
    cell: ({ row }) => (
      <div style={{ flex: '0 0 auto' }}>
        <CellWithRedirectButton name={row.original.name} id={row.original.id} />
      </div>
    ),
  },
];

interface SiteListProps {
  siteData: SiteSimpleDto[];
  onAddRelatedSite: () => void;
}

const SiteList = ({ siteData, onAddRelatedSite }: SiteListProps) => {
  const { isUserOnly } = useCurrentUserInfo();

  const { sorting, searchString, pagination, setSorting, setSearchString, setPagination } =
    useFilters({
      enableUrlMirroring: false,
      enableSorting: false,
      pageSize: CONFIG.ENDPOINTS.ITEMS_PER_PAGE_SMALL,
      sortingState: [],
    });

  const { data, totalItems } = useDataManipulation<SiteSimpleDto>({
    data: siteData || [],
    searchString,
    sorting,
    pagination,
    enableSorting: false,
    getSearchableString: (item) => item.name,
    getSortableValue: (item, sortId) => item[sortId as keyof SiteSimpleDto],
  });

  return (
    <>
      <ButtonAdd sx={{ my: 2 }} onClick={onAddRelatedSite} disabled={isUserOnly}>
        {t('buttonAddRelatedSites')}
      </ButtonAdd>
      <SearchInput value={searchString} onValueChange={setSearchString} sx={{ mb: 2 }} />
      <TanStackTable<SiteSimpleDto>
        columns={columns}
        rows={data}
        itemsCount={totalItems}
        sorting={sorting}
        pagination={pagination}
        onSortingChange={(value) => setSorting(value)}
        onPaginationChange={(value) => setPagination(value)}
      />
    </>
  );
};

export default SiteList;
