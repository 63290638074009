import { GraphDto } from '@appTypes/models/site.dto';
import { SECONDS_MINUTE } from '@settings/constants';
import { convertLocalDateToApiString, createParams } from '@utils/utils';
import { coreApi } from './core';

type GraphArgs = {
  dataLink: string;
  startDate: Date;
  endDate: Date;
  limit: number;
};

export type PreviewParams = {
  siteId: string;
};

export type PreviewPayload = {
  name: string;
  items: string[];
};

type PreviewArgs = PreviewParams & PreviewPayload;

const graphsApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getGraph: builder.query<GraphDto, GraphArgs>({
      query: ({ dataLink, startDate, endDate, limit }) =>
        `${dataLink}?${createParams({
          limit,
          start: convertLocalDateToApiString(startDate),
          end: convertLocalDateToApiString(endDate),
          static_response: 'false',
        })}`,
      keepUnusedDataFor: SECONDS_MINUTE,
    }),
    getCustomGraphPreview: builder.mutation<GraphDto, PreviewArgs>({
      query: ({ siteId, ...payload }) => ({
        url: `sites/${siteId}/preview`,
        method: 'POST',
        data: { ...payload },
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetGraphQuery, useGetCustomGraphPreviewMutation } = graphsApi;
