import { ActionCreator } from '@appTypes/helpers/redux';
import {
  SitesActionsTypes,
  FetchedSites,
  RequestSitesFailed,
  RequestSites,
  RequestSitesSuccess,
  GetSiteSuccess,
  UpdateSitesFilter,
  SetSiteRequestError,
  SetSiteRequestLoading,
  ClearSiteCurrent,
  FetchedSitesCount,
  FetchedGetSite,
  ResetSites,
} from './actionTypes';

export const resetSites: ActionCreator<ResetSites> = () => ({
  type: SitesActionsTypes.RESET_SITES,
});

export const setSiteRequestError: ActionCreator<SetSiteRequestError> = (payload) => ({
  type: SitesActionsTypes.SET_SITE_REQUEST_ERROR,
  payload,
});

export const setSiteRequestLoading: ActionCreator<SetSiteRequestLoading> = (payload) => ({
  type: SitesActionsTypes.SET_SITE_REQUEST_LOADING,
  payload,
});

export const requestSites: ActionCreator<RequestSites> = () => ({
  type: SitesActionsTypes.REQUEST_SITES,
});

export const requestSitesSuccess: ActionCreator<RequestSitesSuccess> = (payload) => ({
  type: SitesActionsTypes.REQUEST_SITES_SUCCESS,
  payload,
});

export const requestSitesFailed: ActionCreator<RequestSitesFailed> = (payload) => ({
  type: SitesActionsTypes.REQUEST_SITES_FAILED,
  payload,
});

export const fetchedSites: ActionCreator<FetchedSites> = ({ page = 1, pageSize = 5 }) => ({
  type: SitesActionsTypes.FETCHED_SITES,
  payload: { page, pageSize },
});

export const getSiteSuccess: ActionCreator<GetSiteSuccess> = (payload) => ({
  type: SitesActionsTypes.GET_SITE_SUCCESS,
  payload,
});

export type SitesAction =
  | RequestSites
  | RequestSitesSuccess
  | RequestSitesFailed
  | FetchedSites
  | GetSiteSuccess
  | UpdateSitesFilter
  | FetchedSitesCount
  | SetSiteRequestLoading
  | ClearSiteCurrent
  | SetSiteRequestError
  | FetchedGetSite
  | ResetSites;
